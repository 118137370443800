import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Sockette from "sockette";
import { getCognitoUserPoolId } from "src/app/common/utils/sessionUtils";
import { setWsMessageReceivedStatus } from "../../actionCreators";
import { Header } from "../Header";
import SideBar from "../SideBar";
import { SERVICES } from "./constants";
import { parseAdminPropertiesData } from "./helper";
import { getAdminProperties } from "./service";
import "./styles.scss";

export const DashboardWrapper = ({ children }) => {
	const [isWSMessageReceived, setIsWSMessageReceived] = useState(false);
	const [adminPropertiesData, setAdminPropertiesData] = useState(null);
	const dispatch = useDispatch();
	const { selectedProperty } = useSelector((state) => state.reservations); // g0et selected property from global state

	function listenProperty(propertyId) {
		const ws = new Sockette(`${process.env.REACT_APP_WS_URL}?propertyId=${propertyId}`, {
			timeout: 5e3,
			maxAttempts: 10,
			onopen: (e) => {
				console.info("WS: Connected");
			},
			onmessage: (e) => {
				console.info("WS: OnMessage");
				setIsWSMessageReceived(true);
			},
			onclose: (e) => {
				console.info("WS: Closed");
				ws.reconnect();
			},
			onerror: (e) => console.log("Error while connecting ws:", e)
		});
	}

	useEffect(() => {
		if (isWSMessageReceived) {
			dispatch(setWsMessageReceivedStatus(true));
			setIsWSMessageReceived(false);
		}
	}, [isWSMessageReceived, dispatch]);

	useEffect(() => {
		if (!selectedProperty) {
			console.log("No property selected");
			return;
		}

		const propertyId = selectedProperty.value;
		if (propertyId) {
			listenProperty(propertyId);
		}
	}, [selectedProperty]);

	useEffect(() => {
		fetchAdminProperties();
	}, []);

	const fetchAdminProperties = async () => {
		try {
			const apiEndpoint = SERVICES.getAdminProperties.apiEndpoint;
			const cognitoUserId = await getCognitoUserPoolId();
			const data = await getAdminProperties(apiEndpoint, { cognitoUserId });
			const parsedData = parseAdminPropertiesData(data);
			setAdminPropertiesData(parsedData);
		} catch (e) {
			console.log(e);
		}
	};

	return (
		<React.Fragment>
			<Header adminProperties={adminPropertiesData} />
			<div className="spacer70"></div>
			<div className="admin-wrapper">
				<SideBar />
				{children}
			</div>
		</React.Fragment>
	);
};
