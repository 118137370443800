import { useState } from "react";
import { CustomField, CustomCheckbox } from "..";

const KeyHandlingSection = () => {
	const [sendKeyAutomaticallyChecked, setSendKeyAutomaticallyChecked] = useState(false);

	return (
		<div className="property-creation-form-section">
			<h2>QR Master</h2>

			<div
				style={{
					display: "grid",
					gridTemplateColumns: "1fr 1fr 1fr",
					gridGap: "2rem"
				}}
			>
				<CustomField
					id="qr_master_account_id"
					label="Account ID"
					name="qr_master_account_id"
				/>
				<CustomField
					id="qr_master_auth_token"
					label="Auth token"
					name="qr_master_auth_token"
				/>
				<CustomField
					id="qr_master_community_no"
					label="Community number"
					name="qr_master_community_no"
				/>

				<CustomField label="Building Number" id="building_no" name="building_no" />

				<CustomField
					label="Area code"
					id="qr_master_area_code"
					name="qr_master_area_code"
				/>
				<CustomField
					label="Phone number"
					id="qr_master_phone_number"
					name="qr_master_phone_number"
				/>
			</div>
			<div
				style={{
					display: "flex",
					alignItems: "center",
					marginTop: 16
				}}
			>
				<CustomCheckbox
					id="send_key_automatically"
					name="send_key_automatically"
					label="Send key automatically"
					direction="right"
					callback={(isChecked) => setSendKeyAutomaticallyChecked(isChecked)}
				/>
				<CustomField
					type="time"
					label="Send time"
					id="send_key_time"
					name="send_key_time"
					disabled={!sendKeyAutomaticallyChecked}
				/>
			</div>
		</div>
	);
};

export default KeyHandlingSection;
