import { Field, useFormikContext } from 'formik';
import { FC, useState } from 'react';

interface Props {
    id: string;
    name: string;
    label: string;
    direction?: "left" | "right";
    callback: (isChecked: boolean) => void;
}

export const CustomCheckbox: FC<Props> = ({ id, name, label, direction = "left", callback }) => {
    // const [checkboxState, setCheckboxState] = useState(0);
    const { setFieldValue } = useFormikContext();

    const handleCheckboxChange = (event: { target: { checked: any; }; }): void => {
        const isChecked: boolean = event.target.checked as boolean;
        setFieldValue(name, isChecked);
        // setCheckboxState(isChecked ? 1 : 0);
        callback(isChecked);
    };

    return (
        <>
            {
                direction === "left" ?
                    <label style={{ textAlign: "right" }}>
                        <span style={{ marginRight: "0.3rem" }}>{label}</span>
                        <Field
                            id={id}
                            name={name}
                            type="checkbox"
                            onChange={handleCheckboxChange}
                        // checked={checkboxState}
                        />
                    </label> :
                    <label style={{ textAlign: "left" }}>
                        <Field
                            id={id}
                            name={name}
                            type="checkbox"
                            onChange={handleCheckboxChange}
                        // checked={checkboxState}
                        />
                        <span style={{ marginLeft: "0.3rem" }}>{label}</span>
                    </label>
            }
        </>
    );
}
