import { RoomLock } from "../entities/room-lock";
import { RoomsLocksRepository } from "../repositories/rooms-locks.repository";

export class GetRoomsLocksByProperty {
    constructor(private readonly roomsLocksRepository: RoomsLocksRepository) { }

    async execute(propertyId: number): Promise<RoomLock[]> {
        return this.roomsLocksRepository.getByProperty(propertyId);
    }
}
