import { FC } from 'react';

interface Props {
  icon: string;
  callback: () => void;
}

export const ActionButtonTable: FC<Props> = ({ icon, callback }) => {
  return (
    <span
      className="action-button"
      onClick={() => callback()}
    >
      <img alt="" src={icon} width="21px" height="21px" />
    </span>
  )
}
