import React, { FC, useReducer } from "react";
import { AdminsContext, adminsReducer } from ".";
import { AdminsRepositoryImpl } from "../data/repositories/admins-repository-impl";
import { Admin } from "../domain/entities/admin";
import { AdminsRepository } from "../domain/repositories/admins-repository";
import { GetAdmins } from "../domain/useCases/get-admins";

export interface AdminsState {
	admins: Admin[];
}

const ADMINS_INITIAL_STATE: AdminsState = {
	admins: [],
};

type Props = {
	children: React.ReactNode;
};

export const AdminsProvider: FC<Props> = ({ children }) => {
	const [state, dispatch] = useReducer(adminsReducer, ADMINS_INITIAL_STATE);

	const initRepository = (): AdminsRepository => {
		const adminsRepository = new AdminsRepositoryImpl();
		return adminsRepository;
	};

	const getAdmins = async (): Promise<void> => {
		const adminsRepository = initRepository();
		const getAdmins = new GetAdmins(adminsRepository);
		const admins = await getAdmins.execute();
		dispatch({ type: "GET_ALL", payload: { admins: admins } });
	};

	return (
		<AdminsContext.Provider
			value={{
				...state,

				// Methods
				getAdmins,
			}}
		>
			{children}
		</AdminsContext.Provider>
	);
}
