import { FC } from "react";
import "./styles.scss";

interface Props {
  onClick?: () => void;
  label?: string;
  isDisabled?: boolean;
  className?: string;
  isLoading?: boolean;
  type?: "button" | "submit" | "reset";
}

export const AppButton: FC<Props> = ({
  onClick,
  label,
  isDisabled,
  className,
  isLoading,
  type = "button",
}) => {
  return (
    <div className={`btn-wrap ${className}`}>
      <button
        type={type}
        className={`black-btn btn 
          ${isDisabled ? "disabled" : ""} 
          ${isLoading ? "loading" : ""}`
        }
        onClick={isDisabled ? () => { } : onClick}
      >
        <span>{label}</span>
        {isLoading && <div className="ld ld-ring ld-spin"></div>}
      </button>
    </div>
  );
};
