import { Auth } from "aws-amplify";
import { getJwtToken } from "src/app/common/utils/sessionUtils";
import { Property } from "../../domain/entities/property";
import { PropertiesRepository } from "../../domain/repositories/properties-repository";

const BASE_URL = process.env.REACT_APP_API_BASE_URL;
export class PropertiesRepositoryImpl implements PropertiesRepository {

  async updateAdmins(propertyId: number, adminIds: number[]): Promise<void> {
    const url = `${BASE_URL}/properties/updateAdmins`;
    const currentUser = await Auth.currentUserInfo();

    try {
      const options = {
        method: "PUT",
        cors: true,
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: currentUser.attributes.email,
          propertyId: propertyId,
          admins: adminIds
        }),
      };

      const response = await fetch(url, options);

      if (response.status !== 200) {
        throw new Error("Error adding property");
      }

      return response.json();
    } catch (error) {
      throw error;
    }
  }

  async updateProperty(property: Property): Promise<any> {
    const url = `${BASE_URL}/properties/updateProperty`;
    const currentUser = await Auth.currentUserInfo();

    try {
      const options = {
        method: "POST",
        cors: true,
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...property,
          rootEmail: currentUser.attributes.email
        }),
      };

      const response = await fetch(url, options);
      const data = await response.json();

      if (data.errorMessage !== undefined) {
        throw new Error(`Error adding property ${data.errorMessage}`);
      }

      return data;
    } catch (error) {
      throw error;
    }
  }

  async addProperty(property: Property): Promise<Property> {
    const url = `${BASE_URL}/properties/createProperty`;
    const currentUser = await Auth.currentUserInfo();

    try {
      const options = {
        method: "POST",
        cors: true,
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...property,
          rootEmail: currentUser.attributes.email
        }),
      };

      const response = await fetch(url, options);
      const data = await response.json();

      if (data.errorMessage !== undefined) {
        throw new Error(`Error adding property ${data.errorMessage}`);
      }

      return data;
    } catch (error) {
      throw error;
    }
  }

  async deleteProperty(propertyId: number): Promise<void> {
    const url =
      `${BASE_URL}/properties/deletePropertyById`;
    const currentUser = await Auth.currentUserInfo();

    try {
      const options = {
        method: "POST",
        cors: true,
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          property_id: propertyId,
          rootEmail: currentUser.attributes.email
        }),
      };

      const response = await fetch(url, options);
      if (response.status !== 200) {
        throw new Error("Error deleting property");
      }
    } catch (error) {
      throw error;
    }
  }

  async getProperties(): Promise<Property[]> {
    const url =
      `${BASE_URL}/properties/getAllProperties`;
    const currentUser = await Auth.currentUserInfo();

    try {
      const options = {
        method: "POST",
        cors: true,
        body: JSON.stringify({ email: currentUser.attributes.email }),
        headers: {
          "Content-Type": "application/json",
        },
      };

      const response = await fetch(url, options);
      if (response.status !== 200) {
        throw new Error("Error gettings properties");
      }
      return response.json();
    } catch (error) {
      throw error;
    }
  }

  async syncReservations(propertyId: any, fromDate: string): Promise<void> {
    const url = `${BASE_URL}/reservations/syncReservationsFromCloudbeds`;

    try {
      const token = await getJwtToken();

      const options = {
        method: "POST",
        cors: true,
        body: JSON.stringify({ propertyId, fromDate }),
        headers: {
          "Authorization": token!,
          "Content-Type": "application/json",
        },
      };

      const response = await fetch(url, options);
      if (response.status !== 200) {
        throw new Error("Error syncing reservations");
      }
      console.log('Reservations synced');
      return response.json();
    } catch (error) {
      throw error;
    }
  }
}
