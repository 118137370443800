import { useFormikContext } from "formik";
import { FC, useEffect, useState } from "react";
import { CustomCheckbox, CustomField } from "..";
import { Property } from "src/app/properties/domain/entities/property";

const CloudbedsSection: FC = () => {
	const { values, setFieldValue } = useFormikContext<Property>();
	const [checked, setChecked] = useState(false);

	useEffect(() => {
		if (!values) return;

		setChecked(values.cloudbeds ?? false);
		if (!values.cloudbeds) {
			clearCloudbedsFields();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [values?.cloudbeds]);

	const clearCloudbedsFields = () => {
		setFieldValue("pms_property_id", "");
		setFieldValue("api_user_key", "");
		setFieldValue("api_user_pwd", "");
	};

	return (
		<div className="property-creation-form-section">
			<div
				style={{
					display: "grid",
					gridTemplateColumns: "1fr 1fr",
					gridGap: "2rem",
					alignItems: "center",
					justifyContent: "space-between"
				}}
			>
				<h2>Cloudbeds</h2>
				<CustomCheckbox
					id="cloudbeds"
					label="Use Cloudbeds"
					name="cloudbeds"
					callback={(isChecked: boolean) => setChecked(isChecked)}
				/>
			</div>
			<CustomField
				disabled={!checked}
				label="Cloudbeds - Property ID"
				id="pms_property_id"
				name="pms_property_id"
			/>
			<CustomField
				disabled={!checked}
				label="Cloudbeds - Client ID"
				id="api_user_key"
				name="api_user_key"
			/>
			<CustomField
				disabled={!checked}
				label="Cloudbeds - Client secret"
				id="api_user_pwd"
				name="api_user_pwd"
			/>
		</div>
	);
};

export default CloudbedsSection;
