import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { PrivateRoute } from "./PrivateRoute";
import { Home } from "../Home";
import { Confirm } from "../Confirm";
import Reservations from "../Dashboard/Components/Reservations";
import ExtraCharges from "../Dashboard/Components/ExtraCharges";
import Administrators from "../Dashboard/Components/Administrators";
import GoorooBooster from "../Dashboard/Components/GoorooBooster";
import Geofencing from "../Geofencing";
import Properties from "../Properties";
import CreateProperty from "../Properties/create";
import { NotFound } from "../NotFound";
import { ProtectedRoute } from "./ProtectedRoute";
import StoresDashboard from "../Stores/Dashboard";
import ImportReservations from "../ImportReservations";
import DetailsProperty from "../Properties/details";
import RoomsLocks from "../RoomsLocks";

export const routes = {
	home: "/",
	reservations: "/reservations",
	extraCharges: "/extra-charges",
	users: "/users",
	goorooBooster: "/gooroo-booster",
	confirm: "/confirm/:id",
	geofencing: "/geofencing",
	properties: "/properties",
	locks: "/locks",
	createProperty: "/properties/create/:id?",
	detailsProperty: "/properties/:id?/details",
	stores: {
	  dashboard: "/stores/dashboard",
	},
	import: "/import",
};

export const AppRoutes = () => {
	return (
		<Router>
			<Switch>
				<ProtectedRoute exact path={routes.home} component={Home} />
				<Route exact path={routes.confirm} component={Confirm} />

				<PrivateRoute path={routes.reservations} component={Reservations} />

				<PrivateRoute exact path={routes.extraCharges} component={ExtraCharges} />
				<PrivateRoute exact path={routes.users} component={Administrators} />
				<PrivateRoute exact path={routes.import} component={ImportReservations} />

				<PrivateRoute exact path={routes.goorooBooster} component={GoorooBooster} />

				<PrivateRoute exact path={routes.geofencing} component={Geofencing} />
				<PrivateRoute
					exact
					path={routes.properties}
					component={Properties}
					requiredRoles={["root", "super_admin"]}
				/>
				<PrivateRoute
					exact
					path={routes.locks}
					component={RoomsLocks}
					requiredRoles={["root", "super_admin"]}
				/>
				<PrivateRoute path={routes.createProperty} component={CreateProperty} />
				<PrivateRoute path={routes.detailsProperty} component={DetailsProperty} />
				<PrivateRoute path={routes.stores.dashboard} component={StoresDashboard} />
				<Route component={NotFound} />
			</Switch>
		</Router>
	);
};
