import { Auth } from "aws-amplify";
import { RoomLock } from "../../domain/entities/room-lock";
import { RoomsLocksRepository } from "../../domain/repositories/rooms-locks.repository";
import { RoomLockDto } from "../dtos/room-lock.dto";

export class ApiRoomsLocksRepository implements RoomsLocksRepository {

  private readonly BASE_URL = process.env.REACT_APP_API_BASE_URL;

  async getByProperty(propertyId: number): Promise<RoomLock[]> {
    const url = `${this.BASE_URL}/reservationKey/getLocks`;
    const currentUser = await Auth.currentUserInfo();

    const requestBody = {
      email: currentUser.attributes.email,
      propertyId: propertyId,
    };

    try {
      const options = {
        method: "POST",
        cors: true,
        body: JSON.stringify(requestBody),
        headers: { "Content-Type": "application/json" },
      };

      const response = await fetch(url, options);

      if (response.status !== 200) {
        throw new Error(`Error getting rooms locks for property ${propertyId}`);
      }

      const roomsLocksDto = await response.json();

      const roomsLocks: RoomLock[] = roomsLocksDto.map((dto: RoomLockDto) => {
        return {
          lockId: dto.lock_id,
          battery: dto.battery,
          signal: dto.signal,
          roomNumber: dto.room_number,
          floorNumber: dto.floor_number,
          gateway: dto.gateway,
          lastUpdate: dto.last_update,
        }
      });

      return roomsLocks;

    } catch (error) {
      throw error;
    }
  }

  async getConfigQr(lockId: string): Promise<string> {
    const url = `${this.BASE_URL}/reservationKey/getQRLockConfiguration`;
    const currentUser = await Auth.currentUserInfo();

    const requestBody = {
      email: currentUser.attributes.email,
      lockId: lockId,
    };

    try {
      const options = {
        method: "POST",
        cors: true,
        body: JSON.stringify(requestBody),
        headers: { "Content-Type": "application/json" },
      };

      const response = await fetch(url, options);

      if (response.status !== 200) {
        throw new Error(`Error getting QR code for config lock (${lockId})`);
      }

      const encodedQr = await response.json();
      return encodedQr.data;

    } catch (error) {
      throw error;
    }
  }
}
