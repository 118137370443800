import React from 'react'
import { version } from "../../../../package.json";

export const VersionText = () => {
    return (
        <p
            style={{
                position: "absolute",
                bottom: "0",
                width: "100%",
                padding: "10px 20px",
                textAlign: "center",
                fontSize: "12px",
                color: "#d1d1d1",
            }}
        > v{version}</p>
    )
}
