import { Formik } from "formik";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import OverlayLoaderSuccessError from "src/app/components/OverlayLoaderSuccessError/OverlayLoaderSuccessError";
import { useProperties } from "src/app/properties/hooks/useProperties";
import { PropertyForm } from "../components/form/PropertyForm";
import { mockData, ValidationSchema } from "../utils/constants";

enum ViewStatus {
	INITIAL = "INITIAL",
	LOADING = "LOADING",
	ERROR = "ERROR",
	SUCCESS = "SUCCESS"
}

const CreateProperty = () => {
	const [status, setStatus] = useState<ViewStatus>(ViewStatus.INITIAL);
	const { createProperty } = useProperties();
	const history = useHistory();

	const goToProperties = () => {
		history.push("/properties");
	};

	const handleSubmit = async (values: any) => {
		setStatus(ViewStatus.LOADING);
		try {
			await createProperty(values);

			setStatus(ViewStatus.SUCCESS);
			setTimeout(() => {
				setStatus(ViewStatus.INITIAL);
				goToProperties();
			}, 2000);
		} catch (error) {
			setStatus(ViewStatus.ERROR);
			setTimeout(() => {
				setStatus(ViewStatus.INITIAL);
			}, 2000);
		}
	};

	return (
		<div className="right-wrapper">
			<div className="tabs-wrapper property-creation-cont">
				<OverlayLoaderSuccessError
					isLoading={status === ViewStatus.LOADING}
					isSuccessful={status === ViewStatus.SUCCESS}
					isError={status === ViewStatus.ERROR}
					message={
						status === ViewStatus.SUCCESS
							? "Property created successfully"
							: status === ViewStatus.ERROR
							? "Error creating property.\nPlease try again."
							: ""
					}
				/>
				<h1>Property creation</h1>
			</div>
			<Formik
				initialValues={mockData}
				onSubmit={handleSubmit}
				validationSchema={ValidationSchema}
			>
				{(props) => {
					const { values, setFieldValue } = props;
					return (
						<PropertyForm id="create" values={values} setFieldValue={setFieldValue} />
					);
				}}
			</Formik>
		</div>
	);
};

export default CreateProperty;
