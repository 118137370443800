import { CustomField } from "..";

const CheckSection = () => {
	return (
		<div className="property-creation-form-section">
			<h2>Check-in/Check-out</h2>

			<div
				style={{
					display: "grid",
					gridTemplateColumns: "1fr 1fr",
					gridGap: "0 2rem"
				}}
			>
				<CustomField
					type="time"
					label="Check-in time"
					id="checkin_time"
					name="checkin_time"
					placeholder="E.g.: 10:00"
				/>
				<CustomField
					type="time"
					label="Early Check-in time"
					id="early_check_in_time"
					name="early_check_in_time"
					placeholder="E.g.: 13:00"
				/>
				<CustomField
					type="time"
					label="Check-out time"
					id="checkout_time"
					name="checkout_time"
					placeholder="E.g.: 16:00"
				/>
				<CustomField
					type="time"
					label="Late check-out time"
					id="late_checkout_time"
					name="late_checkout_time"
					placeholder="E.g.: 17:00"
				/>
				<CustomField
					type="number"
					label="Late check-out reminder hours"
					id="late_checkout_notification_time"
					name="late_checkout_notification_time"
					placeholder="E.g.: 2"
				/>
				<CustomField
					label="Late check-out amount"
					id="late_checkout_amount"
					name="late_checkout_amount"
					type="number"
					placeholder="$0.00"
				/>
			</div>
		</div>
	);
};

export default CheckSection;
