import { ErrorMessage, Field } from "formik";
import { FC } from "react";

interface CustomFieldProps {
    label: string;
    type?: string;
    id: string;
    name: string;
    placeholder?: string;
    as?: string;
    children?: React.ReactNode;
    required?: boolean;
    disabled?: boolean;
}

export const CustomField: FC<CustomFieldProps> = ({
    label,
    type = "text",
    id,
    name,
    placeholder,
    as = "input",
    children,
    required = true,
    disabled = false
}) => {

    return <label
        id={id}
        htmlFor={id}
        className="property-creation-form-item"
    >
        <span data-required={required} id={id}>{label}</span>
        <Field
            id={id}
            required={required}
            as={as}
            type={type}
            name={name}
            style={{ mb: "0.5rem" }}
            placeholder={placeholder}
            disabled={disabled}
        >
            {children}
        </Field>
        <ErrorMessage name={name} component="div" className="error-message" />
    </label>
};
