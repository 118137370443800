import { Property } from "../entities/property";
import { PropertiesRepository } from "../repositories/properties-repository";


export class GetProperties {
    constructor(private readonly propertiesRepository: PropertiesRepository) { }

    async execute(): Promise<Property[]> {
        return this.propertiesRepository.getProperties();
    }
}
