import { Auth } from "aws-amplify";
import { getJwtToken } from "src/app/common/utils/sessionUtils";
import { Admin } from "../../domain/entities/admin";
import { AdminsRepository } from "../../domain/repositories/admins-repository";

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

export class AdminsRepositoryImpl implements AdminsRepository {

    async getAdmins(): Promise<Admin[]> {
        const url =
            `${BASE_URL}/admin/getAdmins`;
        const currentUser = await Auth.currentUserInfo();
        const token = await getJwtToken();

        try {
            const options = {
                method: "POST",
                cors: true,
                body: JSON.stringify({
                    email: currentUser.attributes.email
                }),
                headers: {
                    "Authorization": token!,
                    "Content-Type": "application/json",
                },
            };

            const response = await fetch(url, options);
            if (response.status !== 200) {
                throw new Error("Error gettings properties");
            }

            return response.json();
        } catch (error) {
            throw error;
        }
    }
}