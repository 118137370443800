import { RoomsLocksState } from '.';
import { RoomLock } from '../domain/entities/room-lock';

type RoomsLocksActionType =
    | { type: 'SET_LOCKS_BY_PROPERTY', payload: { propertyId: number, roomsLocks: RoomLock[] } }

export const roomsLocksReducer = (state: RoomsLocksState, action: RoomsLocksActionType): RoomsLocksState => {

    if (action.type === 'SET_LOCKS_BY_PROPERTY') {
        const { propertyId, roomsLocks } = action.payload;
        state.roomsLocks.set(propertyId, [...roomsLocks]);
        return { ...state };
    }

    return state;
}
