import { AppRoutes } from "./pages/AppRoutes";
import { QueryClient, QueryClientProvider } from "react-query";
import { Provider } from "react-redux";
import store from "./store";
import { Auth, Amplify } from "aws-amplify";
import AWS_CONFIG from "../app/common/constants/AwsConstants";
import { NetworkDetector } from "./components/NetworkDetector";
import { version } from "../../package.json";
import { PropertiesProvider } from "./properties/context";
import { SideDrawerProvider } from "./components/SideDrawer/context";
import "./common/utils/scss/global.scss";
import "./common/utils/scss/table.scss";
import { AdminsProvider } from "./admins/context";
import { RoomsLocksProvider } from "./rooms-locks/context";

const App = () => {
	Amplify.configure(AWS_CONFIG);
	Auth.Credentials.accessKeyId = process.env.REACT_APP_IAM_ACCESS_KEY;
	Auth.Credentials.secretAccessKey = process.env.REACT_APP_IAM_SECRET_KEY;
	console.log("Version: ", version);
	const queryClient = new QueryClient({
		defaultOptions: {
			queries: {
				refetchOnWindowFocus: false
			}
		}
	});
	return (
		<Provider store={store}>
			<SideDrawerProvider>
				<AdminsProvider>
					<RoomsLocksProvider>
						<PropertiesProvider>
							<QueryClientProvider client={queryClient}>
								<NetworkDetector>
									<AppRoutes />
								</NetworkDetector>
							</QueryClientProvider>
						</PropertiesProvider>
					</RoomsLocksProvider>
				</AdminsProvider>
			</SideDrawerProvider>
		</Provider>
	);
};

export default App;
