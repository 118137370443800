import { FC } from "react";
import Loader from "react-loader-spinner";
import Popup from "reactjs-popup";

interface Props {
	isOpened: boolean;
	isLoading: boolean;
	encodedQr: string;
	onClose: () => void;
}

export const QrCodePopup: FC<Props> = ({ isOpened, isLoading, encodedQr, onClose }) => {
	return (
		<Popup
			className="popup-admin"
			open={isOpened}
			closeOnDocumentClick={true}
			onClose={onClose}
			children={
				isLoading ? (
					<div className="modal">
						<p style={{ textAlign: "center", fontWeight: "bold", fontSize: 20 }}>
							<Loader
								type="Oval"
								height={48}
								width={48}
								color="#edc44c"
								secondaryColor="#ecd9a0"
							/>
							<br />
							<p style={{ textAlign: "center", fontSize: 16 }}>Getting QR code...</p>
						</p>
					</div>
				) : (
					<>
						<div className="modal">
							<p style={{ textAlign: "center", fontWeight: "bold", fontSize: 20 }}>
								QR ONLY FOR CONFIGURATION
							</p>
							<br />
							<p style={{ textAlign: "center", fontSize: 16 }}>
								You should use this QR only in the case that the lock is
								unconfigured
							</p>
							<br />
							<br />
							<div
								style={{
									display: "flex",
									alignItems: "center",
									justifyContent: "center"
								}}
							>
								<img src={"data:image/png;base64, " + encodedQr} alt="" />
							</div>
							<br />
							<p style={{ color: "red", textAlign: "center", fontSize: 16 }}>
								This QR can't be used to open the lock
							</p>
						</div>
					</>
				)
			}
		></Popup>
	);
};
