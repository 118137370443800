import { FC, useEffect, useState } from "react";
// import { confirmAlert } from "react-confirm-alert";
import DataTable, { TableColumn } from "react-data-table-component";
import { useHistory } from "react-router-dom";
// import ActiveIcon from "src/app/assets/images/active-icon.svg";
import EditIcon from "src/app/assets/images/edit-icon.svg";
// import InactiveIcon from "src/app/assets/images/inactive-icon.svg";
// import DeleteIcon from "src/app/assets/images/incidentals-delete-icon.svg";
import PlusIcon from "src/app/assets/images/plus-icon.svg";
import { AppButton } from "src/app/components/AppButton";
import { GenericError } from "src/app/components/GenericError";
import SideDrawer from "src/app/components/SideDrawer";
import { useSideDrawerContext } from "src/app/components/SideDrawer/context";
import { SkeletonLoader } from "src/app/components/SkeletonLoader";
import { useProperties } from "../../properties/hooks/useProperties";
import { ActionButtonTable } from "./components/ActionButtonTable";
import AssignAdminsContainer from "./components/AssignAdminsContainer";
import moment from "moment";
import { useSelector } from "react-redux";

enum ViewStatus {
	INITIAL = "INITIAL",
	LOADING = "LOADING",
	ERROR = "ERROR",
	SUCCESS = "SUCCESS"
}

type PropertyDataRow = {
	id: number;
	name: string;
	email: string;
	address: string;
	city: string;
	goorooBooster: number;
	holdAmount: number;
	admins: number[];
};

const Properties = () => {
	const [status, setStatus] = useState<ViewStatus>(ViewStatus.LOADING);
	const { getPropertiesByUser } = useProperties();

	useEffect(() => {
		getPropertiesByUser()
			.then(() => setStatus(ViewStatus.SUCCESS))
			.catch((_) => setStatus(ViewStatus.ERROR));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className="right-wrapper">
			{status === ViewStatus.ERROR ? (
				<GenericError />
			) : (
				<>
					<BuildHeader />
					<BuildBody status={status} setStatus={setStatus} />
				</>
			)}
		</div>
	);
};

const BuildHeader = () => {
	const history = useHistory();
	const { syncReservationsFromCloudBeds } = useProperties();
	const { selectedProperty } = useSelector((state: any) => state.reservations);
	const [isSyncing, setIsSyncing] = useState(false);

	const goToCreateProperty = () => {
		history.push("/properties/create");
	};

	const syncReservations = async () => {
		try {
			setIsSyncing(true);
			const propertyId = selectedProperty.value;
			const fromDate = moment(Date.now()).subtract(5, "days").format("YYYY-MM-DD");
			await syncReservationsFromCloudBeds(propertyId, fromDate);
		} catch (error) {
			console.log(error);
		} finally {
			setIsSyncing(false);
		}
	};

	return (
		<div className="tabs-wrapper">
			<h1>Properties</h1>
			<div
				style={{
					display: "flex",
					paddingLeft: "40px",
					paddingRight: "40px",
					alignItems: "center",
					justifyContent: "flex-start"
				}}
			>
				<span>
					<AppButton label="Add" onClick={() => goToCreateProperty()} />
				</span>
				<span style={{ paddingLeft: "32px" }}>
					<AppButton
						isDisabled={isSyncing}
						label="Sync reservations"
						onClick={() => syncReservations()}
					/>
				</span>
			</div>
		</div>
	);
};

interface BodyProps {
	status: ViewStatus;
	setStatus: (status: ViewStatus) => void;
}

const BuildBody: FC<BodyProps> = ({ status, setStatus }) => {
	const history = useHistory();
	const [selectedProperty, setSelectedProperty] = useState({
		id: -1,
		name: "",
		admins: [] as number[]
	});
	const { properties /*deleteProperty*/ } = useProperties();
	const { toggleSideDrawer } = useSideDrawerContext();

	const onEdit = (propertyId: number) => {
		history.push(`/properties/details/${propertyId}`);
	};

	// const onDelete = async (id: number) => {
	// 	try {
	// 		setStatus(ViewStatus.LOADING);
	// 		await deleteProperty(id);
	// 		setStatus(ViewStatus.SUCCESS);
	// 		setTimeout(() => {
	// 			setStatus(ViewStatus.INITIAL);
	// 		}, 1000);
	// 	} catch (e) {
	// 		console.log(e);
	// 		setStatus(ViewStatus.ERROR);
	// 	}
	// };

	const onPressAssignAdmins = (property: PropertyDataRow) => {
		setSelectedProperty({
			id: property.id,
			name: property.name,
			admins: property.admins
		});
		toggleSideDrawer();
	};

	const columns: TableColumn<PropertyDataRow>[] = [
		{
			name: "Name",
			sortable: true,
			selector: (row) => row.name
		},
		{
			name: "Email",
			sortable: true,
			selector: (row) => row.email
		},
		{
			name: "Address",
			sortable: true,
			selector: (row) => row.address
		},
		{
			name: "City",
			sortable: true,
			maxWidth: "150px",
			selector: (row) => row.city
		},
		// {
		// 	name: "GoorooBooster",
		// 	sortable: true,
		// 	selector: (row) => row.goorooBooster,
		// 	maxWidth: "150px",
		// 	cell: (row) => (
		// 		<div>
		// 			{row.goorooBooster === 1 ? (
		// 				<img alt="" src={ActiveIcon} width="18px" height="18px" />
		// 			) : (
		// 				<img alt="" src={InactiveIcon} width="18px" height="18px" />
		// 			)}
		// 		</div>
		// 	)
		// },
		{
			name: "Hold Amount",
			maxWidth: "150px",
			sortable: true,
			selector: (row) => row.holdAmount
		},
		{
			name: "Actions",
			selector: (row) => row.id,
			maxWidth: "200px",
			cell: (row) => (
				<div
					style={{
						display: "flex",
						alignItems: "start",

						justifyContent: "start",
						minWidth: "110px"
					}}
				>
					<ActionButtonTable icon={EditIcon} callback={() => onEdit(row.id)} />
					{/* <ActionButtonTable
						icon={DeleteIcon}
						callback={() => showDeleteAlert(row.name, () => onDelete(row.id))}
					/> */}
					<ActionButtonTable icon={PlusIcon} callback={() => onPressAssignAdmins(row)} />
				</div>
			)
		}
	];

	const data = properties.map((property) => ({
		id: property.id!,
		name: property.property_name!,
		email: property.email!,
		address: property.address!,
		city: property.city!,
		goorooBooster: property.gooroo_booster!,
		holdAmount: property.hold_amount!,
		admins: property.admins?.map((id) => id) ?? []
	}));

	loadingSkeleton();

	return (
		<div className="table-wrapper">
			<SideDrawer
				title="Admininstrators"
				subtitle={`Select all the administrator for ${selectedProperty.name}`}
				description={`By default, the property owner is an administrator.`}
				children={
					<AssignAdminsContainer
						propertyId={selectedProperty.id}
						assignedAdmins={selectedProperty.admins}
					/>
				}
			/>
			<DataTable
				striped
				defaultSortAsc
				columns={columns}
				data={data}
				fixedHeader
				fixedHeaderScrollHeight="600px"
				pagination
			/>
		</div>
	);

	function loadingSkeleton() {
		if (status === ViewStatus.LOADING) {
			columns.map((column) => {
				return (column.cell = () => <SkeletonLoader width={100} height={10} />);
			});
		}
	}
};

// const showDeleteAlert = (propertyName: string, onAccept: () => void) => {
// 	try {
// 		confirmAlert({
// 			title: "Delete property",
// 			message: `Do you want to delete ${propertyName}?`,

// 			buttons: [
// 				{
// 					label: "No",
// 					onClick: () => { }
// 				},
// 				{
// 					label: "Yes",
// 					onClick: () => onAccept()
// 				}
// 			]
// 		});
// 	} catch (error) {
// 		console.log(error);
// 	}
// };

export default Properties;
