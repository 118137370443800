// List of properties to show in dropdown in header
export const PROPERTIES_LIST = [
  { key: "Tuck hotel", value: "Bright Hotel", title: "Bright Hotel Admin" },
  { key: "Bright hotel", value: "Bright Hotel", title: "Bright Hotel Admin" },
];

export const SIDEBAR_TABS = {
  reservations: "Reservations",
  //extraCharges: "Extra Charges",
  extraCharges: "Additional charges",
  users: "Users",
  goorooBooster: "GoorooBooster",
  geofencing: "Guests",
  properties: "Properties",
  locks: "Locks",
  stores: "Stores",
  import: "Import",
};

export const logoutModal = {
  title: "Logout",
  message: "Are you sure you want to logout?",
  no: "No",
  yes: "Logout",
};
