import { SideDrawerState } from '.';


type SideDrawerActionType =
    | { type: 'TOGGLE_SIDE_DRAWER' }


export const sideDrawerReducer = (state: SideDrawerState, action: SideDrawerActionType): SideDrawerState => {

    switch (action.type) {
        case 'TOGGLE_SIDE_DRAWER':
            return {
                ...state,
                isDrawerOpened: !state.isDrawerOpened
            }

        default:
            return state;
    }
}
