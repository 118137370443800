import React, { useState } from "react";
import PropTypes from "prop-types";
import moreInfo from "src/app/assets/images/three-dots.svg";
import { StatusMapper } from "./statusMapper";
import { Spinner } from "src/app/components/Spinner";

export const Row = ({
  data,
  onStatusClick,
  onNameClick,
  onMoreOptionsClick,
  showMoreOptions,
  componentToShowOnMoreOptions,
  isArrivalsTab,
  isBookingsTab,
  keyEnabled,
}) => {
  // eslint-disable-next-line
  const [loading, setLoading] = useState(false);
  // eslint-disable-next-line
  const [loadingStatus, setLoadingStatus] = useState(null);

  const getValueToShow = (data) => {
    return data ? data : "-";
  };

  return (
    <React.Fragment>
      <tr className={(data.index + 1) % 2 === 0 ? "even" : "odd"}>
        <td>
          <span className="visiter-name" onClick={() => onNameClick(data)}>
            {getValueToShow(data.name)}
          </span>
          <span className="visiter-mobile">{data.phone_number}</span>
        </td>

        <td>
          {getValueToShow(data.reservation_id)}/{" "}
          {getValueToShow(data.no_of_nights)}{" "}
          {data.no_of_nights > 1 ? "Nights" : "Night"}
        </td>

        <td>{`${getValueToShow(data.checkin_date)}, ${data.checkin_time}`}</td>

        <td>{`${getValueToShow(data.checkout_date)}, ${
          data.checkout_time
        }`}</td>

        {(isArrivalsTab() || isBookingsTab()) && (
          <td>
            <StatusMapper
              status={data.status}
              adminStatus={data.admin_checkin_status}
              onClick={() => onStatusClick(data)}
              keyEnabled={keyEnabled}
            />
          </td>
        )}

        <td>
          <span className="room-number">{getValueToShow(data.room_no)}</span>
        </td>

        <td>
          <div className="more-info">
            {keyEnabled ? (
              <div
                className={`moreinfo-icon ${showMoreOptions && "active"}`}
                onClick={onMoreOptionsClick}
              >
                <img src={moreInfo} alt="moreInfo" />
              </div>
            ) : (
              <div
                className={"moreinfo-icon disabled"}
                onClick={onMoreOptionsClick}
              >
                <img src={moreInfo} alt="moreInfo" />
              </div>
            )}
          </div>

          {showMoreOptions && componentToShowOnMoreOptions}
        </td>
      </tr>

      {loading && <Spinner loaderStatus={loadingStatus} />}
    </React.Fragment>
  );
};

Row.propTypes = {
  data: PropTypes.object.isRequired,
  onStatusClick: PropTypes.func,
  onMoreOptionsClick: PropTypes.func,
  showMoreOptions: PropTypes.bool,
  componentToShowOnMoreOptions: PropTypes.node,
  isArrivalsTab: PropTypes.func,
  keyEnabled: PropTypes.bool,
};
