import { Form } from 'formik'
import { FC } from 'react'
import { AppButton } from 'src/app/components/AppButton'
import { Property } from 'src/app/properties/domain/entities/property'
import CheckSection from './CheckSection'
import CloudbedsSection from './CloudbedsSection'
import GeneralSection from './GeneralSection'
import KeyHandlingSection from './KeyHandlingSection'
import PaymentsSection from './PaymentsSection'
import './styles.scss'

interface Props {
    id: string;
    labelButton?: string;
    values: Property;
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
}

export const PropertyForm: FC<Props> = ({ id, labelButton = "Create", values, setFieldValue }) => {
    return (
        <Form
            key={id}
            className="property-creation-form">
            <GeneralSection />
            <div
                style={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr",
                    gap: "0 2rem",
                }}>
                <CheckSection />
                <CloudbedsSection />
            </div>
            <KeyHandlingSection />
            <PaymentsSection
                values={values}
                setFieldValue={(field, value, shouldValidate) => setFieldValue(field, value, shouldValidate)}
            />
            <div style={{ height: "1rem" }} />
            <AppButton type="submit" label={labelButton} />
        </Form >
    )
}
