export const SERVICES = {
  getReservationDetails: {
    apiEndpoint: "reservations/getReservationDetails",
  },
  getReservationData: {
    apiEndpoint: "reservations/getReservationData",
  },
  createAdminLog: {
    apiEndpoint: "admin/createAdminLog",
  },
  getCloudBedsRooms: {
    apiEndpoint: "reservations/getCloudBedsRooms",
  },
  createReservation: {
    apiEndpoint: "reservations/createReservation",
  },
  updateReservation: {
    apiEndpoint: "reservations/updateReservation",
  },
  getAvailableRoomTypes: {
    apiEndpoint: "reservations/getAvailableRoomTypes",
  },
  getRatePlans: {
    apiEndpoint: "reservations/getRatePlans",
  },
  getStripePublicToken: {
    key: "stripe",
    apiEndpoint: "stripe/getStripePublicToken",
  },
  refundReservation: {
    key: "stripe",
    apiEndpoint: "stripe/refundReservation",
  },
  chargeReservationAutomatically: {
    key: "stripe",
    apiEndpoint: "stripe/chargeReservationAutomatically",
  },
  updateReservationStatusToConfirmed: {
    apiEndpoint: "reservations/updateReservationStatusToConfirmed",
  },
  charge: {
    apiEndpoint: "stripe/charge",
  },
  updatePaymentAmount: {
    apiEndpoint: "reservations/updatePaymentAmount",
  },
  updateDiscountAmount: {
    apiEndpoint: "reservations/updateDiscountAmount",
  },
  createNote: {
    apiEndpoint: "notes/createNote",
  },
  getChangeReasons: {
    apiEndpoint: "reservations/getChangeReasons",
  },
  getPropertyDetails: {
    apiEndpoint: "reservations/getPropertyDetails",
  },
  getRoomTypes: {
    apiEndpoint: "reservations/getRoomTypes",
  },
};
