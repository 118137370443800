import React, { FC, useReducer } from "react";
import { RoomsLocksContext, roomsLocksReducer } from ".";
import { RoomsLocksRepository } from "../domain/repositories/rooms-locks.repository";
import { GetRoomsLocksByProperty } from "../domain/usecases/get-rooms-locks-by-property";
import { ApiRoomsLocksRepository } from "../data/repositories/api-rooms-locks.repository";
import { RoomLock } from "../domain/entities/room-lock";
import { GetConfigQr } from "../domain/usecases/get-config-qr";

export interface RoomsLocksState {
	roomsLocks: Map<number, RoomLock[]>;
}

const INITIAL_STATE: RoomsLocksState = {
	roomsLocks: new Map()
};

type Props = {
	children: React.ReactNode;
};

export const RoomsLocksProvider: FC<Props> = ({ children }) => {
	const [state, dispatch] = useReducer(roomsLocksReducer, INITIAL_STATE);

	const initRepository = (): RoomsLocksRepository => {
		return new ApiRoomsLocksRepository();
	};

	const fetchRoomsLocks = async (propertyId: number): Promise<void> => {
		const roomsLocksRepository = initRepository();
		const getByProperty = new GetRoomsLocksByProperty(roomsLocksRepository);
		const roomsLocks = await getByProperty.execute(propertyId);
		dispatch({ type: "SET_LOCKS_BY_PROPERTY", payload: { propertyId, roomsLocks } });
	};

	const getConfigQr = async (lockId: string): Promise<string> => {
		const roomsLocksRepository = initRepository();
		const getConfigQr = new GetConfigQr(roomsLocksRepository);
		const encodedQrCode = await getConfigQr.execute(lockId);
		return encodedQrCode;
	};

	return (
		<RoomsLocksContext.Provider
			value={{
				...state,

				// Methods
				fetchRoomsLocks,
				getConfigQr,
			}}
		>
			{children}
		</RoomsLocksContext.Provider>
	);
};
