import AxiosBuilder from "src/app/common/services/AxiosBuilder";
import { getJwtToken } from "src/app/common/utils/sessionUtils";

export const getReservationDetailsService = async (apiEndpoint, params) => {
  const token = await getJwtToken();

  return new Promise(function (resolve, reject) {
    new AxiosBuilder(apiEndpoint)
      .withAuth(token)
      .withParams({
        reservationId: params.reservationId,
        propertyId: params.propertyId,
      })
      .GET()
      .then((response) => {
        let r = response?.data;
        if (response && response.data && typeof response.data === "string") {
          r = JSON.parse(response.data);
        }
        resolve(r);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getReservationDataService = async (apiEndpoint, params) => {
  const token = await getJwtToken();

  return new Promise(function (resolve, reject) {
    new AxiosBuilder(apiEndpoint)
      .withAuth(token)
      .withParams({
        reservationId: params.reservationId,
        propertyId: params.propertyId,
      })
      .GET()
      .then((response) => {
        let r = response?.data;
        if (response && response.data && typeof response.data === "string") {
          r = JSON.parse(response.data);
        }
        resolve(r);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const createAdminLogService = async (apiEndpoint, params) => {
  const token = await getJwtToken();

  return new Promise(function (resolve, reject) {
    new AxiosBuilder(apiEndpoint, {
      data: JSON.stringify({
        propertyId: params.propertyId,
        adminCognitoId: params.adminCognitoId,
        type: params.type,
        data: params.data,
      }),
    })
      .withAuth(token)
      .POST()
      .then((response) => {
        let r = response?.data;
        if (response && response.data && typeof response.data === "string") {
          r = JSON.parse(response.data);
        }
        resolve(r);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getCloudBedsRoomsService = async (apiEndpoint, params) => {
  const token = await getJwtToken();

  return new Promise(function (resolve, reject) {
    new AxiosBuilder(apiEndpoint, {
      data: JSON.stringify({
        propertyId: params.propertyId,
        roomTypeId: params.roomTypeId,
        checkInDate: params.checkInDate,
        checkOutDate: params.checkOutDate,
      }),
    })
      .withAuth(token)
      .POST()
      .then((response) => {
        let r = response?.data;
        if (response && response.data && typeof response.data === "string") {
          r = JSON.parse(response.data);
        }
        resolve(r);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const createReservationService = async (apiEndpoint, params) => {
  const token = await getJwtToken();

  return new Promise(function (resolve, reject) {
    new AxiosBuilder(apiEndpoint, {
      data: JSON.stringify({
        propertyId: params.propertyId,
        guestName: params.guestName,
        guestEmail: params.guestEmail,
        guestPhoneNumber: params.guestPhoneNumber,
        roomTypeId: params.roomTypeId,
        roomNumber: params.roomNumber,
        roomTypeName: params.roomTypeName,
        roomRateId: params.roomRateId,
        roomRatePlan: params.roomRatePlan,
        bookingDate: params.bookingDate,
        checkInDate: params.checkInDate,
        checkOutDate: params.checkOutDate,
        adminCheckinStatus: params.adminCheckinStatus,
        status: params.status,
        subTotal: params.subTotal,
        cityTax: params.cityTax,
        occupancyTax: params.occupancyTax,
        amenityFee: params.amenityFee,
        otherFees: params.otherFeesAmount,
        grandTotal: params.grandTotal,
      }),
    })
      .withAuth(token)
      .POST()
      .then((response) => {
        let r = response?.data;
        if (response && response.data && typeof response.data === "string") {
          r = JSON.parse(response.data);
        }
        resolve(r);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const updateReservationService = async (apiEndpoint, params) => {
  const token = await getJwtToken();

  return new Promise(function (resolve, reject) {
    new AxiosBuilder(apiEndpoint, {
      data: JSON.stringify({
        reservationId: params.reservationId,
        propertyId: params.propertyId,
        guestName: params.guestName,
        guestEmail: params.guestEmail,
        guestPhoneNumber: params.guestPhoneNumber,
        roomTypeId: params.roomTypeId,
        roomId: params.roomId,
        roomNumber: params.roomNumber,
        roomTypeName: params.roomTypeName,
        roomRateId: params.roomRateId,
        roomRatePlan: params.roomRatePlan,
        checkInDate: params.checkInDate,
        checkOutDate: params.checkOutDate,
        status: params.status,
        subTotal: params.subTotal,
        cityTax: params.cityTax,
        occupancyTax: params.occupancyTax,
        amenityFee: params.amenityFee,
        grandTotal: params.grandTotal,
        otherFees: params.otherFeesAmount,
        cardLastNumber: params.cardLastNumber,
      }),
    })
      .withAuth(token)
      .POST()
      .then((response) => {
        let r = response?.data;
        if (response && response.data && typeof response.data === "string") {
          r = JSON.parse(response.data);
        }
        resolve(r);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const refundReservationService = async (apiEndpoint, params) => {
  const token = await getJwtToken();

  return new Promise(function (resolve, reject) {
    new AxiosBuilder(apiEndpoint, {
      data: JSON.stringify({
        propertyId: params.propertyId,
        reservationId: params.reservationId,
      }),
    })
      .withAuth(token)
      .POST()
      .then((response) => {
        let r = response?.data;
        if (response && response.data && typeof response.data === "string") {
          r = JSON.parse(response.data);
        }
        resolve(r);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const chargeReservationAutomaticallyService = async (
  apiEndpoint,
  params
) => {
  const token = await getJwtToken();

  return new Promise(function (resolve, reject) {
    new AxiosBuilder(apiEndpoint, {
      data: JSON.stringify({
        reservationId: params.reservationId,
        propertyId: params.propertyId,
        description: params.description,
        amount: params.amount,
        cardLastNumber: params.cardLastNumber,
      }),
    })
      .withAuth(token)
      .POST()
      .then((response) => {
        let r = response?.data;
        if (response && response.data && typeof response.data === "string") {
          r = JSON.parse(response.data);
        }
        resolve(r);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getAvailableRoomTypesService = async (apiEndpoint, params) => {
  const token = await getJwtToken();

  return new Promise(function (resolve, reject) {
    new AxiosBuilder(apiEndpoint, {
      data: JSON.stringify({
        propertyId: params.propertyId,
        checkInDate: params.checkInDate,
        checkOutDate: params.checkOutDate,
      }),
    })
      .withAuth(token)
      .POST()
      .then((response) => {
        let r = response?.data;
        if (response && response.data && typeof response.data === "string") {
          r = JSON.parse(response.data);
        }
        resolve(r);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getRoomTypesService = async (apiEndpoint, params) => {
  const token = await getJwtToken();

  return new Promise(function (resolve, reject) {
    new AxiosBuilder(apiEndpoint, {
      data: JSON.stringify({
        propertyId: params.propertyId,
      }),
    })
      .withAuth(token)
      .POST()
      .then((response) => {
        let r = response?.data;
        if (response && response.data && typeof response.data === "string") {
          r = JSON.parse(response.data);
        }
        resolve(r);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const stripeHoldService = async (stripeId, amount) => {
  const token = await getJwtToken();
  const apiEndpoint = "stripe/hold";

  return new Promise(function (resolve, reject) {
    new AxiosBuilder(apiEndpoint, {
      data: JSON.stringify({
        stripeId,
        amount,
      }),
    })
      .withAuth(token)
      .POST()
      .then((response) => {
        let r = response?.data;
        if (response && response.data && typeof response.data === "string") {
          r = JSON.parse(response.data);
        }
        resolve(r);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getStripePublicTokenService = async (apiEndpoint, propertyId) => {
  const token = await getJwtToken();
  return new Promise(function (resolve, reject) {
    new AxiosBuilder(apiEndpoint, {
      data: JSON.stringify({
        propertyId,
      }),
    })
      .withAuth(token)
      .POST()
      .then((response) => {
        let r = response?.data;
        if (response && response.data) {
          r = JSON.parse(response.data);
        }
        resolve(r);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getRatePlansService = async (apiEndpoint, params) => {
  const token = await getJwtToken();

  return new Promise(function (resolve, reject) {
    new AxiosBuilder(apiEndpoint, {
      data: JSON.stringify({
        propertyId: params.propertyId,
        roomTypeId: params.roomTypeId,
        checkInDate: params.checkInDate,
        checkOutDate: params.checkOutDate,
      }),
    })
      .withAuth(token)
      .POST()
      .then((response) => {
        let r = response?.data;
        if (response && response.data && typeof response.data === "string") {
          r = JSON.parse(response.data);
        }
        resolve(r);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const updateReservationStatusToConfirmedService = async (
  apiEndpoint,
  params
) => {
  const token = await getJwtToken();

  return new Promise(function (resolve, reject) {
    new AxiosBuilder(apiEndpoint, {
      data: JSON.stringify({
        reservationId: params.reservationId,
        propertyId: params.propertyId,
      }),
    })
      .withAuth(token)
      .POST()
      .then((response) => {
        let r = response?.data;
        if (response && response.data && typeof response.data === "string") {
          r = JSON.parse(response.data);
        }
        resolve(r);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const chargeService = async (apiEndpoint, params) => {
  const token = await getJwtToken();

  return new Promise(function (resolve, reject) {
    new AxiosBuilder(apiEndpoint, {
      data: JSON.stringify({
        propertyId: params.propertyId,
        paymentMethodId: params.paymentMethodId,
        description: params.description,
        amount: params.amount,
      }),
    })
      .withAuth(token)
      .POST()
      .then((response) => {
        let r = response?.data;
        if (response && response.data && typeof response.data === "string") {
          r = JSON.parse(response.data);
        }
        resolve(r);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const createExtraChargeService = async (apiEndpoint, params) => {
  const token = await getJwtToken();

  return new Promise(function (resolve, reject) {
    new AxiosBuilder(apiEndpoint, {
      data: JSON.stringify({
        propertyId: params.propertyId,
        pmsPropertyId: params.pmsPropertyId,
        reservationId: params.reservationId,
        description: params.description,
        amount: params.amount,
        quantity: params.quantity,
        paymentId: params.paymentId,
      }),
    })
      .withAuth(token)
      .POST()
      .then((response) => {
        let r = response?.data;
        if (response && response.data && typeof response.data === "string") {
          r = JSON.parse(response.data);
        }
        resolve(r);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const updatePaymentAmountService = async (apiEndpoint, params) => {
  const token = await getJwtToken();

  return new Promise(function (resolve, reject) {
    new AxiosBuilder(apiEndpoint, {
      data: JSON.stringify({
        reservationId: params.reservationId,
        propertyId: params.propertyId,
        amount: params.amount,
      }),
    })
      .withAuth(token)
      .POST()
      .then((response) => {
        let r = response?.data;
        if (response && response.data && typeof response.data === "string") {
          r = JSON.parse(response.data);
        }
        resolve(r);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const updateDiscountAmountService = async (apiEndpoint, params) => {
  const token = await getJwtToken();

  return new Promise(function (resolve, reject) {
    new AxiosBuilder(apiEndpoint, {
      data: JSON.stringify({
        reservationId: params.reservationId,
        propertyId: params.propertyId,
        amount: params.amount,
      }),
    })
      .withAuth(token)
      .POST()
      .then((response) => {
        let r = response?.data;
        if (response && response.data && typeof response.data === "string") {
          r = JSON.parse(response.data);
        }
        resolve(r);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const createNoteService = async (apiEndpoint, params) => {
  const token = await getJwtToken();

  return new Promise(function (resolve, reject) {
    new AxiosBuilder(apiEndpoint, {
      data: JSON.stringify({
        propertyId: params.propertyId,
        reservationId: params.reservationId,
        note: params.note,
        deleteAvailable: params.deleteAvailable,
        createdBy: params.createdBy,
      }),
    })
      .withAuth(token)
      .POST()
      .then((response) => {
        let r = response?.data;
        if (response && response.data && typeof response.data === "string") {
          r = JSON.parse(response.data);
        }
        resolve(r);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getChangeReasonsService = async (apiEndpoint, params) => {
  const token = await getJwtToken();

  return new Promise(function (resolve, reject) {
    new AxiosBuilder(apiEndpoint, {
      data: JSON.stringify({
        propertyId: params.propertyId,
      }),
    })
      .withAuth(token)
      .POST()
      .then((response) => {
        let r = response?.data;
        if (response && response.data && typeof response.data === "string") {
          r = JSON.parse(response.data);
        }
        resolve(r);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getPropertyDetailsService = async (apiEndpoint, params) => {
  const token = await getJwtToken();

  return new Promise(function (resolve, reject) {
    new AxiosBuilder(apiEndpoint, {
      data: JSON.stringify({
        propertyId: params.propertyId,
      }),
    })
      .withAuth(token)
      .POST()
      .then((response) => {
        let r = response?.data;
        if (response && response.data && typeof response.data === "string") {
          r = JSON.parse(response.data);
        }
        resolve(r);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
