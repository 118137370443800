import React, { FC, useReducer } from "react";
import { SideDrawerContext, sideDrawerReducer } from ".";

export interface SideDrawerState {
	isDrawerOpened: boolean;
}

const SIDE_DRAWER_INITIAL_STATE: SideDrawerState = {
	isDrawerOpened: false,
};

type Props = {
	children: React.ReactNode;
};

export const SideDrawerProvider: FC<Props> = ({ children }) => {
	const [state, dispatch] = useReducer(sideDrawerReducer, SIDE_DRAWER_INITIAL_STATE);

	const toggleSideDrawer = () => {
		dispatch({ type: "TOGGLE_SIDE_DRAWER" });
	};


	return (
		<SideDrawerContext.Provider
			value={{
				...state,

				// Methods
				toggleSideDrawer,
			}}
		>
			{children}
		</SideDrawerContext.Provider>
	);
}
