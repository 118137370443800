import React, { useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import "./styles.scss";
import { AppButton } from "src/app/components/AppButton";
import { useSelector } from "react-redux";
import { SERVICES } from "./constants";
import {
  createExtraChargeService,
  getExtraChargesService,
  deleteExtraChargesService,
  getReservationAndExtraChargesService,
  createExtraChargeItemService,
  updateExtraChargeItemService,
  getExtraChargesItemsService,
  deleteExtraChargesItemService,
  chargeService,
  refundService,
  createAdminLogService,
} from "./services";
import { parseReservationDetails } from "./helper";
import { TextField } from "src/app/components/TextField";
import { SelectField } from "src/app/components/SelectField";
import { SuccessIcon } from "src/app/components/SuccessIcon";
import { WarningIcon } from "src/app/components/WarningIcon";
import EditIcon from "src/app/assets/images/edit-icon.svg";
import DeleteIcon from "src/app/assets/images/incidentals-delete-icon.svg";
import IncidentalsIcon from "src/app/assets/images/zero-incidentals.svg";
import InactiveIcon from "src/app/assets/images/inactive-icon.svg";
import ActiveIcon from "src/app/assets/images/active-icon.svg";
import Loader from "react-loader-spinner";
import { SkeletonLoader } from "src/app/components/SkeletonLoader";
import { getTimezoneOffsetRegion } from "src/app/common/utils/dateUtils";
import { Toast } from "src/app/components/Toast";
import { CurrencyField } from "src/app/components/CurrencyField";
import { NumericField } from "src/app/components/NumericField";
import { deleteModal } from "./constants";
import { confirmAlert } from "react-confirm-alert";
import Popup from "reactjs-popup";
import { Fragment } from "react";
import CloseIcon from "src/app/assets/images/close-icon.svg";
import SwitchIcon from "src/app/assets/images/switch-icon.svg";

export const ExtraCharges = ({ onDismiss, selectedResId, setErrorOccured }) => {
  const { selectedProperty } = useSelector((state) => state.reservations); // get selected property from global state
  const [reservationDetails, setReservationDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [processing, setProcessing] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [description, setDescription] = useState("");
  const [amount, setAmount] = useState(0);
  const [total, setTotal] = useState(0);
  const [quantity, setQuantity] = useState(1);
  const [extraCharges, setExtraCharges] = useState([]);
  const [toastMessage, setToastMessage] = useState("");
  const [toastError, setToastError] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [showDescriptionError, setShowDescriptionError] = useState(false);
  const [showAmountError, setShowAmountError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [extraChargesItems, setExtraChargesItems] = useState([]);
  const [activeExtraChargesItems, setActiveExtraChargesItems] = useState([]);
  // eslint-disable-next-line
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedItemRequiresQuantity, setSelectedItemRequiresQuantity] =
    useState(false);
  const [extraChargesDescription, setExtraChargesDescription] = useState("");
  const [extraChargesAmount, setExtraChargesAmount] = useState(0);
  const [extraChargesRequiresQuantity, setExtraChargesRequiresQuantity] =
    useState(false);
  const [chatbot, setChatbot] = useState(false);
  const [panel, setPanel] = useState(false);
  const [preCheckin, setPreCheckin] = useState(false);
  const [required, setRequired] = useState(false);
  const [extraField, setExtraField] = useState(false);
  const [extraFieldDescription, setExtraFieldDescription] = useState("");
  const [chargeReservationDays, setChargeReservationDays] = useState(false);
  const [extraChargesActive, setExtraChargesActive] = useState(true);
  const [extraChargesProcessing, setExtraChargesProcessing] = useState(false);
  // eslint-disable-next-line
  const [extraChargesDeleting, setExtraChargesDeleting] = useState(false);
  const [
    showExtraChargesDescriptionError,
    setShowExtraChargesDescriptionError,
  ] = useState(false);
  const [showExtraChargesAmountError, setShowExtraChargesAmountError] =
    useState(false);
  const [
    // eslint-disable-next-line
    showExtraChargesChatbotCodeError,
    setShowExtraChargesChatbotCodeError,
  ] = useState(false);
  const [extraChargesErrorMessage, setExtraChargesErrorMessage] = useState("");
  // eslint-disable-next-line
  const [extraChargesloading, setExtraChargesLoading] = useState(true);
  const [extraChargesEdit, setExtraChargesEdit] = useState(false);
  const [extraChargesEditId, setExtraChargesEditId] = useState(null);
  const [manualCharge, setManualCharge] = useState(false);
  const [open, setOpen] = useState(false);

  const closeModal = () => {
    setShowExtraChargesDescriptionError(false);
    setShowExtraChargesAmountError(false);
    setExtraChargesDescription("");
    setExtraChargesAmount(0);
    setChatbot(false);
    setPanel(false);
    setPreCheckin(false);
    setExtraChargesRequiresQuantity(false);
    setExtraChargesActive(true);
    setExtraChargesEdit(false);
    setExtraChargesEditId(null);
    setChargeReservationDays(false);
    setRequired(false);
    setExtraField(false);
    setExtraFieldDescription("");
    setOpen(false);
  };

  useEffect(() => {
    getReservationAndExtraCharges();
    getExtraChargesByReservation();
    getExtraChargesItems();
    // eslint-disable-next-line
  }, []);

  const showDeleteAlert = (id, paymentId, index, e) => {
    try {
      setProcessing(true);
      setDeleting(true);

      confirmAlert({
        title: deleteModal.title,
        message: deleteModal.message,

        buttons: [
          {
            label: deleteModal.no,
            onClick: () => {},
          },
          {
            label: deleteModal.yes,
            onClick: () => {
              handleDeleteExtraCharges(id, paymentId, index);
            },
          },
        ],
      });
    } catch (error) {
      handleError();
    } finally {
      setProcessing(false);
      setDeleting(false);
    }
  };

  const handleAddExtraChargesClick = async () => {
    try {
      setProcessing(true);
      setDeleting(true);
      setShowToast(false);
      await createExtraCharge();
    } catch (error) {
      handleError();
    } finally {
      setProcessing(false);
      setDeleting(false);
    }
  };

  const getReservationAndExtraCharges = async () => {
    const { apiEndpoint } = SERVICES.getReservationAndExtraCharges;
    try {
      const response = await getReservationAndExtraChargesService(apiEndpoint, {
        reservationId: selectedResId,
        propertyId: selectedProperty.value,
        timezoneRegion: getTimezoneOffsetRegion(),
      });
      
      const parsedReservation = parseReservationDetails({ ...response });
			setReservationDetails(parsedReservation);
    } catch (error) {
      handleError();
    } finally {
      setLoading(false);
    }
  };

  const getExtraChargesItems = async () => {
    const { apiEndpoint } = SERVICES.getExtraChargesItems;
    try {
      const response = await getExtraChargesItemsService(apiEndpoint, {
        propertyId: selectedProperty.value,
      });
      if (response) {
        const items = response.map((item, i) => {
          const extraChargeItem = {
            id: item.id,
            description: item.description,
            amount: item.amount,
            requiresQuantity: item.requires_quantity,
            chatbot: item.chatbot,
            panel: item.panel,
            preCheckin: item.pre_checkin,
            extraField: item.extra_field,
            extraFieldDescription: item.extra_field_description,
            required: item.required,
            chargeReservationDays: item.charge_reservation_days,
            active: item.active,
            paymentId: item.payment_id,
            value: item.description,
            label: item.description,
          };

          return extraChargeItem;
        });

        let activeItems = [];
        response.forEach((item) => {
          const extraChargeItem = {
            id: item.id,
            description: item.description,
            amount: item.amount,
            requiresQuantity: item.requires_quantity,
            chatbot: item.chatbot,
            panel: item.panel,
            preCheckin: item.pre_checkin,
            extraField: item.extra_field,
            extraFieldDescription: item.extra_field_description,
            required: item.required,
            chargeReservationDays: item.charge_reservation_days,
            active: item.active,
            paymentId: item.payment_id,
            value: item.description,
            label: item.description,
          };
          if (item.active === 1 && item.panel) {
            activeItems.push(extraChargeItem);
          }
        });

        setExtraChargesItems(items);
        setActiveExtraChargesItems(activeItems);
      } else {
        setExtraChargesItems([]);
      }
    } catch (error) {
      handleError();
    } finally {
      setExtraChargesLoading(false);
    }
  };

  const createExtraCharge = async () => {
    setProcessing(true);
    setShowDescriptionError(false);
    setShowAmountError(false);
    let fieldsValidated = true;
    const { apiEndpoint } = SERVICES.createExtraCharge;
    const { property_id, pms_property_id } = reservationDetails;

    try {
      if (!description || description.trim() === "") {
        setErrorMessage("Description is required");
        setShowDescriptionError(true);
        fieldsValidated = false;
      } else if (!amount || amount.trim() === "") {
        setErrorMessage("Amount is required");
        setShowAmountError(true);
        fieldsValidated = false;
      } else if (parseFloat(amount) <= 0) {
        setErrorMessage("Amount must be greater than $0.00");
        setShowAmountError(true);
        fieldsValidated = false;
      }

      if (fieldsValidated) {
        const chargeAmount = manualCharge ? amount : total;
        const chargeDescription = manualCharge
          ? description
          : `${description} - ${quantity}`;

        const paymentResult = await charge(chargeDescription, chargeAmount);

        if (paymentResult.status === "succeeded") {
          setProcessing(true);
          await createExtraChargeService(apiEndpoint, {
            propertyId: property_id,
            pmsPropertyId: pms_property_id,
            reservationId: selectedResId,
            description: description,
            amount: chargeAmount,
            quantity: quantity,
            paymentId: paymentResult.id,
          });

          // Create Log
          const data = {
            action: "Create",
            message: "Create extra charge",
            reservationId: selectedResId,
            data: {
              propertyId: property_id,
              pmsPropertyId: pms_property_id,
              reservationId: selectedResId,
              description: description,
              amount: chargeAmount,
              quantity: quantity,
              paymentId: paymentResult.id,
            },
          };
          await createAdminLog("Extra Charges", JSON.stringify(data));

          await getReservationAndExtraCharges();
          await getExtraChargesByReservation();

          setDescription("");
          setAmount(0);
          setQuantity(1);
          setTotal(0);
          setExtraChargesItems([]);
          await getExtraChargesItems();
        } else {
          setToastMessage("Unable to complete the charge");
          setToastError(true);
          setShowToast(true);
        }
      }
    } catch (error) {
      setToastMessage(error.message);
      setToastError(true);
      setShowToast(true);
    } finally {
      setProcessing(false);
    }
  };

  const charge = async (description, amount) => {
    setProcessing(true);
    const { apiEndpoint } = SERVICES.charge;
    const { property_id, payment_method_id } = reservationDetails;

    try {
      const paymentResult = await chargeService(apiEndpoint, {
        propertyId: property_id,
        paymentMethodId: payment_method_id,
        description: description,
        amount: amount,
      });

      return paymentResult;
    } catch (error) {
      setToastMessage("Error to charge extra charges");
      setToastError(true);
      setShowToast(true);
    } finally {
      setProcessing(false);
    }
  };

  const refund = async (paymentId) => {
    setDeleting(true);
    const { apiEndpoint } = SERVICES.refund;
    
    try {
      const refundResult = await refundService(apiEndpoint, {
        propertyId: selectedProperty.value,
        paymentId: paymentId
      });

      return refundResult;
    } catch (error) {
      setToastMessage("Error to refund extra charges");
      setToastError(true);
      setShowToast(true);
    } finally {
      setDeleting(false);
    }
  };

  const getExtraChargesByReservation = async () => {
    const { apiEndpoint } = SERVICES.getExtraCharges;
    try {
      const response = await getExtraChargesService(apiEndpoint, {
        propertyId: selectedProperty.value,
        reservationId: selectedResId,
        timezoneRegion: getTimezoneOffsetRegion(),
      });

      response && setExtraCharges(response);
    } catch (error) {
      setToastMessage("Error to get extra charges");
      setToastError(true);
      setShowToast(true);
    }
  };

  const handleDeleteExtraCharges = async (id, paymentId, index) => {
    try {
      setShowToast(false);

      const refundResult = await refund(paymentId);

      if (refundResult.status === "succeeded") {
        const filteredItems = activeExtraChargesItems
          .slice(0, index)
          .concat(
            activeExtraChargesItems.slice(
              index + 1,
              activeExtraChargesItems.length
            )
          );
        setExtraCharges(filteredItems);
        await removeExtraCharges(id);
      }
    } catch (error) {
      setToastMessage("Error to delete the extra charge");
      setToastError(true);
      setShowToast(true);
    } finally {
      setDeleting(false);
    }
  };

  const removeExtraCharges = async (id) => {
    setDeleting(true);
    const { apiEndpoint } = SERVICES.deleteExtraCharges;
    const { property_id } = reservationDetails;

    try {
      await deleteExtraChargesService(apiEndpoint, {
        propertyId: property_id,
        reservationId: selectedResId,
        id: id,
      });

      const data = {
        action: "Delete",
        message: "Delete extra charge",
        reservationId: selectedResId,
        data: {
          propertyId: property_id,
          reservationId: selectedResId,
          id: id,
        },
      };
      await createAdminLog("Extra Charges", JSON.stringify(data));

      await getReservationAndExtraCharges();
      await getExtraChargesByReservation();
      await getExtraChargesItems();
      setDescription("");
      setAmount(0);
      setQuantity(1);
      setTotal(0);
    } catch (error) {
      setToastMessage("Error to delete the extra charge");
      setToastError(true);
      setShowToast(true);
    } finally {
      setDeleting(false);
    }
  };

  const handleError = (error) => {
    setLoading(false);
    // sending the value to parent to show toast error.
    // setErrorOccured(true);
  };

  const getValueToShow = (data) => {
    return data ? data : "-";
  };

  const handleQuantity = async (value) => {
    setQuantity(value);

    if (value) {
      const totalAmount = parseFloat(amount) * parseFloat(value);
      setTotal(totalAmount.toFixed(2));
    } else {
      const totalAmount = parseFloat(amount);
      setTotal(totalAmount.toFixed(2));
    }
  };

  const handleNewExtraChargeItem = async () => {
    setOpen((o) => !o);
  };

  const setExtraChargesItem = async (item) => {
    if (item) {
      setSelectedItem(item);
      setAmount(item.amount);
      setTotal(item.amount);
      if (item.requiresQuantity) {
        setQuantity(1);
        setSelectedItemRequiresQuantity(true);
      } else {
        setQuantity(1);
        setSelectedItemRequiresQuantity(false);
      }
    }
  };

  //Extra charges items
  const createExtraChargeItem = async () => {
    setExtraChargesProcessing(true);
    setShowExtraChargesDescriptionError(false);
    setShowExtraChargesAmountError(false);
    setShowExtraChargesChatbotCodeError(false);

    let fieldsValidated = true;
    const { apiEndpoint } = SERVICES.createExtraChargeItem;
    const { property_id } = reservationDetails;

    try {
      if (!extraChargesDescription || extraChargesDescription.trim() === "") {
        setExtraChargesErrorMessage("Description is required");
        setShowExtraChargesDescriptionError(true);
        fieldsValidated = false;
      } else if (!extraChargesAmount || extraChargesAmount.trim() === "") {
        setExtraChargesErrorMessage("Amount is required");
        setShowExtraChargesAmountError(true);
        fieldsValidated = false;
      } else if (parseFloat(extraChargesAmount) <= 0) {
        setExtraChargesErrorMessage("Amount must be greater than $0.00");
        setShowExtraChargesAmountError(true);
        fieldsValidated = false;
      }

      if (fieldsValidated) {
        await createExtraChargeItemService(apiEndpoint, {
          propertyId: property_id,
          description: extraChargesDescription,
          amount: extraChargesAmount,
          requiresQuantity: extraChargesRequiresQuantity,
          chatbot: chatbot,
          panel: panel,
          preCheckin: preCheckin,
          extraField: extraField,
          extraFieldDescription: extraFieldDescription,
          isRequired: required,
          chargeReservationDays: chargeReservationDays,
          active: extraChargesActive,
        });

        await getExtraChargesItems();
        setExtraChargesDescription("");
        setExtraChargesAmount(0);
        setExtraChargesRequiresQuantity(false);
        setChatbot(false);
        setPanel(false);
        setPreCheckin(false);
        setExtraField(false);
        setExtraFieldDescription("");
        setRequired(false);
        setChargeReservationDays(false);
        setExtraChargesActive(true);
      }
    } catch (error) {
      setToastMessage(error.message);
      setToastError(true);
      setShowToast(true);
    } finally {
      setExtraChargesProcessing(false);
    }
  };

  //Extra charges items
  const updateExtraChargeItem = async () => {
    setExtraChargesProcessing(true);
    const id = extraChargesEditId;
    setExtraChargesEditId(null);
    setShowExtraChargesDescriptionError(false);
    setShowExtraChargesAmountError(false);
    setShowExtraChargesChatbotCodeError(false);

    let fieldsValidated = true;
    const { apiEndpoint } = SERVICES.updateExtraChargeItem;
    const { property_id } = reservationDetails;

    try {
      if (!extraChargesDescription || extraChargesDescription.trim() === "") {
        setExtraChargesErrorMessage("Description is required");
        setShowExtraChargesDescriptionError(true);
        fieldsValidated = false;
      } else if (!extraChargesAmount || extraChargesAmount.trim() === "") {
        setExtraChargesErrorMessage("Amount is required");
        setShowExtraChargesAmountError(true);
        fieldsValidated = false;
      } else if (parseFloat(extraChargesAmount) <= 0) {
        setExtraChargesErrorMessage("Amount must be greater than $0.00");
        setShowExtraChargesAmountError(true);
        fieldsValidated = false;
      }

      if (fieldsValidated) {
        await updateExtraChargeItemService(apiEndpoint, {
          extraChargeItemId: id,
          propertyId: property_id,
          description: extraChargesDescription,
          amount: extraChargesAmount,
          requiresQuantity: extraChargesRequiresQuantity,
          chatbot: chatbot,
          panel: panel,
          preCheckin: preCheckin,
          extraField: extraField,
          extraFieldDescription: extraFieldDescription,
          isRequired: required,
          chargeReservationDays: chargeReservationDays,
          active: extraChargesActive,
        });

        await getExtraChargesItems();
        setExtraChargesDescription("");
        setExtraChargesAmount(0);
        setExtraChargesRequiresQuantity(false);
        setChatbot(false);
        setPanel(false);
        setPreCheckin(false);
        setExtraField(false);
        setExtraFieldDescription("");
        setRequired(false);
        setChargeReservationDays(false);
        setExtraChargesActive(true);
        setExtraChargesEdit(false);
        setExtraChargesEditId(null);
      }
    } catch (error) {
      setToastMessage(error.message);
      setToastError(true);
      setShowToast(true);
    } finally {
      setExtraChargesProcessing(false);
    }
  };

  const handleAddExtraChargesItemClick = async () => {
    setShowToast(false);
    await createExtraChargeItem();
  };

  const handleUpdateExtraChargesItemClick = async () => {
    setShowToast(false);
    await updateExtraChargeItem();
  };

  const handleDeleteExtraChargesItem = async (id, index) => {
    await handleCancelEditExtraChargesItem();
    setShowToast(false);
    const filteredItems = extraChargesItems
      .slice(0, index)
      .concat(extraChargesItems.slice(index + 1, extraChargesItems.length));
    setExtraChargesItems(filteredItems);
    await removeExtraChargesItem(id);
  };

  const removeExtraChargesItem = async (id) => {
    setExtraChargesDeleting(true);
    const { apiEndpoint } = SERVICES.deleteExtraChargesItem;
    const { property_id } = reservationDetails;

    try {
      await deleteExtraChargesItemService(apiEndpoint, {
        propertyId: property_id,
        extraChargeItemId: id,
      });

      await getExtraChargesItems();
      setExtraChargesDescription("");
      setExtraChargesAmount(0);
      setExtraChargesRequiresQuantity(false);
      setChatbot(true);
      setPanel(true);
      setPreCheckin(false);
      setExtraField(false);
      setExtraFieldDescription("");
      setRequired(false);
      setChargeReservationDays(false);
      setExtraChargesActive(true);
      setExtraChargesEdit(false);
      setExtraChargesEditId(null);
    } catch (error) {
      setToastMessage("Error to delete the extra charges item");
      setToastError(true);
      setShowToast(true);
    } finally {
      setExtraChargesDeleting(false);
    }
  };

  const handleEditExtraChargesItem = async (id, index) => {
    setShowExtraChargesDescriptionError(false);
    setShowExtraChargesAmountError(false);
    setShowExtraChargesChatbotCodeError(false);
    setShowToast(false);
    const item = extraChargesItems[index];
    setExtraChargesDescription(item.description);
    setExtraChargesAmount(item.amount);
    setExtraChargesRequiresQuantity(item.requiresQuantity);
    setChatbot(item.chatbot);
    setPanel(item.panel);
    setPreCheckin(item.preCheckin);
    setExtraField(item.extraField);
    setExtraFieldDescription(
      item.extraFieldDescription ? item.extraFieldDescription : ""
    );
    setRequired(item.required);
    setChargeReservationDays(item.chargeReservationDays);
    setExtraChargesActive(item.active);

    setExtraChargesEdit(true);
    setExtraChargesEditId(item.id);
  };

  const handleCancelEditExtraChargesItem = async () => {
    setShowExtraChargesDescriptionError(false);
    setShowExtraChargesAmountError(false);
    setShowExtraChargesChatbotCodeError(false);
    setShowToast(false);
    setExtraChargesDescription("");
    setExtraChargesAmount("");
    setExtraChargesRequiresQuantity(false);
    setChatbot(false);
    setPanel(false);
    setPreCheckin(false);
    setExtraField(false);
    setExtraFieldDescription("");
    setRequired(false);
    setChargeReservationDays(false);
    setExtraChargesActive(true);
    setExtraChargesEdit(false);
    setExtraChargesEditId(null);
  };

  const handleChargeOption = async () => {
    setDescription("");
    setAmount(0);
    setQuantity(1);
    setTotal(0);

    if (manualCharge) {
      setManualCharge(false);
    } else {
      setManualCharge(true);
    }
  };

  const createAdminLog = async (type, data) => {
    setLoading(true);
    const { apiEndpoint } = SERVICES.createAdminLog;
    try {
      const user = await Auth.currentUserInfo();

      const params = {
        propertyId: selectedProperty.value,
        adminCognitoId: user.attributes.sub,
        type: type,
        data: data,
      };
      await createAdminLogService(apiEndpoint, params);
    } catch (error) {
      handleError();
    } finally {
      setLoading(false);
    }
  };

  return (
    <React.Fragment>
      <React.Fragment>
        {loading ? (
          <div className="extra-charges-loader">
            <Loader type="Oval" color="#030303" height={50} width={50} />
          </div>
        ) : (
          <React.Fragment>
            <Popup open={open} closeOnDocumentClick onClose={closeModal}>
              <div className="modal">
                <div className="close-button" onClick={closeModal}>
                  <img alt="" src={CloseIcon} width="24px" height="24px" />
                </div>
                <h2>Manage Extra Charges</h2>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    gap: "10px",
                  }}
                >
                  <TextField
                    extraStyles="text-field-description"
                    label="Description"
                    value={extraChargesDescription}
                    setValue={setExtraChargesDescription}
                    disabled={extraChargesProcessing}
                    showError={showExtraChargesDescriptionError}
                    errorMessage={extraChargesErrorMessage}
                  />
                  <CurrencyField
                    inputId="amount"
                    inputName="amount"
                    extraStyles="text-field-amount-no-padding"
                    label="Amount"
                    value={extraChargesAmount}
                    setValue={setExtraChargesAmount}
                    disabled={extraChargesProcessing}
                    showError={showExtraChargesAmountError}
                    errorMessage={extraChargesErrorMessage}
                    allowNegativeValue={false}
                    prefix={"$"}
                    intlConfig={{ locale: "en-US", currency: "USD" }}
                  />
                </div>

                <div className="field-group-items">
                  <div>
                    <label className="checkbox-component">
                      <input
                        type="checkbox"
                        onChange={(event) => {
                          setChatbot(event.target.checked);
                        }}
                        checked={chatbot}
                        disabled={extraChargesProcessing}
                      />
                      <span className="checkmark"></span>
                      Chatbot
                    </label>
                  </div>

                  <div>
                    <label className="checkbox-component">
                      <input
                        type="checkbox"
                        onChange={(event) => {
                          setPanel(event.target.checked);
                        }}
                        checked={panel}
                        disabled={extraChargesProcessing}
                      />
                      <span className="checkmark"></span>
                      Panel
                    </label>
                  </div>

                  {(chatbot || panel) && (
                    <div>
                      <label className="checkbox-component">
                        <input
                          type="checkbox"
                          onChange={(event) => {
                            setExtraChargesRequiresQuantity(
                              event.target.checked
                            );
                          }}
                          checked={extraChargesRequiresQuantity}
                          disabled={extraChargesProcessing}
                        />
                        <span className="checkmark"></span>
                        Requires quantity
                      </label>
                    </div>
                  )}

                  <div>
                    <label className="checkbox-component">
                      <input
                        type="checkbox"
                        onChange={(event) => {
                          setPreCheckin(event.target.checked);
                        }}
                        checked={preCheckin}
                        disabled={extraChargesProcessing}
                      />
                      <span className="checkmark"></span>
                      Pre Check-in
                    </label>
                  </div>

                  <div>
                    <label className="checkbox-component">
                      <input
                        type="checkbox"
                        onChange={(event) => {
                          setExtraChargesActive(event.target.checked);
                        }}
                        checked={extraChargesActive}
                        disabled={extraChargesProcessing}
                      />
                      <span className="checkmark"></span>
                      Active
                    </label>
                  </div>
                </div>
                {preCheckin ? (
                  <div className="field-group-items">
                    <div>
                      <label className="checkbox-component">
                        <input
                          type="checkbox"
                          onChange={(event) => {
                            setRequired(event.target.checked ? 1 : 0);
                          }}
                          checked={required}
                          disabled={extraChargesProcessing}
                        />
                        <span className="checkmark"></span>
                        Required
                      </label>
                    </div>
                    <div>
                      <label className="checkbox-component">
                        <input
                          type="checkbox"
                          onChange={(event) => {
                            setChargeReservationDays(event.target.checked);
                          }}
                          checked={chargeReservationDays}
                          disabled={extraChargesProcessing}
                        />
                        <span className="checkmark"></span>
                        Charge whole stay
                      </label>
                    </div>
                    <div>
                      <label className="checkbox-component">
                        <input
                          type="checkbox"
                          onChange={(event) => {
                            setExtraField(event.target.checked);
                          }}
                          checked={extraField}
                          disabled={extraChargesProcessing}
                        />
                        <span className="checkmark"></span>
                        Extra field
                      </label>
                    </div>

                    {extraField ? (
                      <div>
                        <TextField
                          extraStyles="text-field-description"
                          label="Extra field description"
                          value={extraFieldDescription}
                          setValue={setExtraFieldDescription}
                          disabled={extraChargesProcessing}
                        />
                      </div>
                    ) : null}
                  </div>
                ) : null}

                <div className="btn-container-row">
                  {extraChargesEditId ? (
                    <button
                      className="cancel-item-btn"
                      onClick={handleCancelEditExtraChargesItem}
                      disabled={extraChargesProcessing}
                    >
                      <span>Cancel</span>
                    </button>
                  ) : null}

                  <AppButton
                    isLoading={extraChargesProcessing}
                    label={!extraChargesEdit ? "Save" : "Update"}
                    onClick={
                      !extraChargesEdit
                        ? handleAddExtraChargesItemClick
                        : handleUpdateExtraChargesItemClick
                    }
                    isDisabled={extraChargesProcessing}
                  />
                </div>

                {!extraChargesItems || extraChargesItems.length === 0 ? (
                  <div className="no-extra-charges">
                    <div className="no-extra-charges-icon">
                      <img
                        alt=""
                        src={IncidentalsIcon}
                        width="64px"
                        height="64px"
                      />
                    </div>
                    <div className="no-extra-charges-message">
                      No extra charges items found
                    </div>
                  </div>
                ) : (
                  <div className="extra-charges-table-wrapper">
                    <table className="extra-charges-table">
                      <tbody>
                        <tr>
                          <th className="extra-charges-description">
                            Description
                          </th>
                          <th className="extra-charges-amount">Amount</th>
                          <th className="extra-charges-date">
                            Requires Quantity
                          </th>
                          <th className="extra-charges-date">Active</th>
                          <th className="extra-charges-action"></th>
                          <th className="extra-charges-action"></th>
                        </tr>

                        {extraChargesItems.map((extraChargeItem, i) => {
                          return (
                            <tr
                              key={i}
                              className={(i + 1) % 2 === 0 ? "even" : "odd"}
                            >
                              <td className="extra-charges-description">
                                <span className="description-text">
                                  {extraChargeItem.description}
                                </span>
                              </td>
                              <td className="extra-charges-amount">
                                $ {extraChargeItem.amount}
                              </td>
                              <td className="extra-charges-date">
                                {extraChargeItem.requiresQuantity ? (
                                  <img
                                    alt=""
                                    src={ActiveIcon}
                                    width="18px"
                                    height="18px"
                                  />
                                ) : (
                                  <img
                                    alt=""
                                    src={InactiveIcon}
                                    width="18px"
                                    height="18px"
                                  />
                                )}
                              </td>
                              <td className="extra-charges-date">
                                {extraChargeItem.active ? (
                                  <img
                                    alt=""
                                    src={ActiveIcon}
                                    width="18px"
                                    height="18px"
                                  />
                                ) : (
                                  <img
                                    alt=""
                                    src={InactiveIcon}
                                    width="18px"
                                    height="18px"
                                  />
                                )}
                              </td>

                              <td className="extra-charges-action">
                                <span
                                  className="extra-charges-delete"
                                  onClick={(e) => {
                                    if (!extraChargesProcessing) {
                                      handleEditExtraChargesItem(
                                        extraChargeItem.id,
                                        i
                                      );
                                    }
                                  }}
                                >
                                  <img
                                    alt=""
                                    src={EditIcon}
                                    width="21px"
                                    height="21px"
                                  />
                                </span>
                              </td>

                              <td className="extra-charges-action">
                                <span
                                  className="extra-charges-delete"
                                  onClick={(e) => {
                                    if (!extraChargesProcessing) {
                                      handleDeleteExtraChargesItem(
                                        extraChargeItem.id,
                                        i
                                      );
                                    }
                                  }}
                                >
                                  <img
                                    alt=""
                                    src={DeleteIcon}
                                    width="21px"
                                    height="21px"
                                  />
                                </span>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            </Popup>

            <div className="extra-charges">
              <div className="extra-charges-card-details">
                {/* Extra Charges Total */}
                <div className="extra-charges-details">
                  <span className="extra-charges-title">
                    EXTRA CHARGES TOTAL
                  </span>
                  {processing || deleting ? (
                    <span className="extra-charges-text">
                      <SkeletonLoader width={100} height={10} />
                    </span>
                  ) : (
                    <span className="extra-charges-text">
                      $
                      {getValueToShow(
                        reservationDetails &&
                          reservationDetails.extracharges_amount
                          ? parseFloat(
                              reservationDetails.extracharges_amount
                            ).toFixed(2)
                          : "-"
                      )}
                    </span>
                  )}
                </div>

                {/* Extra charges Card details */}
                {reservationDetails &&
                reservationDetails.hold_transaction_lastfour ? (
                  <div className="card-details">
                    <span className="extra-charges-title">
                      Last four digits of card <SuccessIcon />
                    </span>
                    <span className="extra-charges-text">
                      {getValueToShow(
                        reservationDetails &&
                          reservationDetails.hold_transaction_lastfour
                      )}
                    </span>
                  </div>
                ) : (
                  <div className="noCredit-found">
                    <WarningIcon /> No credit card details available
                  </div>
                )}
              </div>

              {reservationDetails ? (
                reservationDetails.hold_transaction_lastfour && (
                  <Fragment>
                    <div className="select-option">
                      <span className="select-option-icon">
                        <img
                          alt=""
                          src={SwitchIcon}
                          width="16px"
                          height="16px"
                        />
                      </span>
                      <span
                        className="select-option-label"
                        onClick={() => {
                          if (!processing && !deleting) {
                            handleChargeOption();
                          }
                        }}
                      >
                        {!manualCharge ? "Manual Charge" : "Predefined Charge"}
                      </span>
                    </div>

                    {!manualCharge ? (
                      <Fragment>
                        <div className="select-extra-charges">
                          <SelectField
                            newItem={handleNewExtraChargeItem}
                            extraStyles="text-field-description"
                            styles={{
                              control: (provided, state) => ({
                                ...provided,

                                padding: 5,
                              }),
                              singleValue: (provided, state) => {
                                const opacity = state.isDisabled ? 0.5 : 1;
                                const transition = "opacity 300ms";

                                return { ...provided, opacity, transition };
                              },
                            }}
                            label="Extra Charges"
                            options={activeExtraChargesItems}
                            placeholder={"Select an extra charge"}
                            theme={(theme) => ({
                              ...theme,
                              borderRadius: 4,
                              colors: {
                                ...theme.colors,
                                primary25: "#FFD865",
                                primary50: "#FFD865",
                                primary: "black",
                              },
                            })}
                            setValue={setDescription}
                            setItem={setExtraChargesItem}
                            disabled={processing}
                            isClearable={processing ? false : true}
                            showError={showDescriptionError}
                            errorMessage={errorMessage}
                          />
                        </div>

                        {description ? (
                          <div className="new-incidental">
                            <div className="fields-group">
                              <CurrencyField
                                inputId="amount"
                                inputName="amount"
                                extraStyles="text-field-amount-no-padding "
                                label="Amount"
                                value={amount}
                                setValue={setAmount}
                                disabled={true}
                                showError={showAmountError}
                                errorMessage={errorMessage}
                                allowNegativeValue={false}
                                prefix={"$"}
                                intlConfig={{
                                  locale: "en-US",
                                  currency: "USD",
                                }}
                              />
                              {selectedItemRequiresQuantity ? (
                                <NumericField
                                  inputId="quantity"
                                  inputName="quantity"
                                  extraStyles="text-field-numeric"
                                  label="Quantity"
                                  value={quantity}
                                  setValue={handleQuantity}
                                  disabled={processing}
                                  showError={showAmountError}
                                  errorMessage={errorMessage}
                                  allowNegativeValue={false}
                                  prefix={""}
                                  intlConfig={{
                                    locale: "en-US",
                                    currency: "USD",
                                  }}
                                />
                              ) : null}
                            </div>

                            <CurrencyField
                              inputId="total"
                              inputName="total"
                              extraStyles="text-field-amount"
                              label="Total"
                              value={total}
                              setValue={setTotal}
                              disabled={true}
                              showError={showAmountError}
                              errorMessage={errorMessage}
                              allowNegativeValue={false}
                              prefix={"$"}
                              intlConfig={{ locale: "en-US", currency: "USD" }}
                            />

                            <div className="btn-container">
                              <AppButton
                                className="btn-add"
                                isLoading={processing}
                                label={"Charge"}
                                onClick={handleAddExtraChargesClick}
                                isDisabled={processing}
                              />
                            </div>
                          </div>
                        ) : null}
                      </Fragment>
                    ) : null}

                    {manualCharge ? (
                      <Fragment>
                        <div className="new-extra-charges">
                          <div className="fields-group">
                            <TextField
                              extraStyles="text-field-description"
                              label="Description"
                              value={description}
                              setValue={setDescription}
                              disabled={processing}
                              showError={showDescriptionError}
                              errorMessage={errorMessage}
                            />

                            <CurrencyField
                              inputId="amount"
                              inputName="amount"
                              extraStyles="text-field-amount"
                              label="Amount"
                              value={amount}
                              setValue={setAmount}
                              disabled={processing}
                              showError={showAmountError}
                              errorMessage={errorMessage}
                              allowNegativeValue={false}
                              prefix={"$"}
                              intlConfig={{ locale: "en-US", currency: "USD" }}
                            />
                          </div>
                          <div className="btn-container">
                            <AppButton
                              className="btn-add"
                              isLoading={processing}
                              label={"Charge"}
                              onClick={handleAddExtraChargesClick}
                              isDisabled={processing}
                            />
                          </div>
                        </div>
                      </Fragment>
                    ) : null}
                  </Fragment>
                )
              ) : (
                <div className="release-completed">
                  <span className="release-completed-text">
                    Reservation was closed for extra charges at{" "}
                    {reservationDetails && reservationDetails.release_date}{" "}
                  </span>
                  <SuccessIcon width="100px" height="100px" />
                </div>
              )}
              {!extraCharges || extraCharges.length === 0 ? (
                <div className="no-extra-charges">
                  <div className="no-extra-charges-icon">
                    <img
                      alt=""
                      src={IncidentalsIcon}
                      width="64px"
                      height="64px"
                    />
                  </div>
                  <div className="no-extra-charges-message">
                    No extra charges found
                  </div>
                </div>
              ) : deleting ? (
                <div className="extra-charges-loader">
                  <Loader type="Oval" color="#030303" height={50} width={50} />
                </div>
              ) : (
                <div className="extra-charges-table-wrapper">
                  <table className="extra-charges-table">
                    <tbody>
                      <tr>
                        <th className="extra-charges-description">
                          Description
                        </th>
                        <th className="extra-charges-amount">Amount</th>
                        <th className="extra-charges-date">Date</th>
                        <th className="extra-charges-action"></th>
                      </tr>

                      {extraCharges.map((extraCharge, i) => {
                        return (
                          <tr
                            key={i}
                            className={(i + 1) % 2 === 0 ? "even" : "odd"}
                          >
                            <td className="extra-charges-description">
                              <span className="description-text">
                                {extraCharge.description + " - "}
                                {parseInt(extraCharge.quantity) + " "}
                                {extraCharge.extraFieldValue &&
                                  "(" + extraCharge.extraFieldValue + ")"}
                              </span>
                            </td>
                            <td className="extra-charges-amount">
                              $ {extraCharge.amount}
                            </td>
                            <td className="extra-charges-date">
                              {extraCharge.createdAt}
                            </td>
                            {reservationDetails &&
                            extraCharge.description !== "Late Check-out Fee" ? (
                              <td className="extra-charges-action">
                                <span
                                  className="extra-charges-delete"
                                  onClick={(e) => {
                                    if (!processing && !deleting) {
                                      showDeleteAlert(
                                        extraCharge.id,
                                        extraCharge.paymentId,
                                        i,
                                        e
                                      );
                                    }
                                  }}
                                >
                                  <img
                                    alt=""
                                    src={DeleteIcon}
                                    width="21px"
                                    height="21px"
                                  />
                                </span>
                              </td>
                            ) : (
                              <td></td>
                            )}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </React.Fragment>
        )}

        <Toast
          message={toastMessage}
          activeClassName={showToast ? "active" : ""}
          onDismiss={() => setShowToast(false)}
          isError={toastError}
        />
      </React.Fragment>
    </React.Fragment>
  );
};
