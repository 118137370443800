import React from "react";
import { Page, Text, View, Document, StyleSheet } from "@react-pdf/renderer";
import FolioTitle from "./components/FolioTitle";
import FolioTableHeader from "./components/FolioTableHeader";
import FolioTableRow from "./components/FolioTableRow";
import FolioTableFooter from "./components/FolioTableFooter";
import FolioTotal from "./components/FolioTotal";
import FolioFooter from "./components/FolioFooter";

export const FolioDocument = ({ data }) => {
  const { reservation, extraCharges, incidentals, property } = data;

  const reservationCharges = [
    {
      id: 1,
      description: `Room rate - ${reservation.room_type_name}`,
      quantity: "",
      amount: reservation.subtotal_amount,
      created_at: reservation.booking_date,
    },
    {
      id: 2,
      description: "City Tax",
      quantity: "",
      amount: reservation.city_tax_amount,
      created_at: reservation.booking_date,
    },
    {
      id: 3,
      description: "Ocuppancy Tax",
      quantity: "",
      amount: reservation.occupancy_tax_amount,
      created_at: reservation.booking_date,
    },
    {
      id: 4,
      description: "Amenity Fee",
      quantity: "",
      amount: reservation.amenity_fee_amount,
      created_at: reservation.booking_date,
    },
    {
      id: 4,
      description: "Other Fees",
      quantity: "",
      amount: reservation.other_fees_amount
        ? reservation.other_fees_amount
        : Number(0.0).toFixed(2),
      created_at: reservation.booking_date,
    },
    {
      id: 5,
      description: "Discount",
      quantity: "",
      amount: reservation.discount_amount
        ? reservation.discount_amount
        : Number(0.0).toFixed(2),
      created_at: reservation.booking_date,
    },
    {
      id: 5,
      description: "Reservation Refund",
      quantity: "",
      amount: reservation.reservation_refund_date
        ? Number(reservation.grandtotal_amount * -1).toFixed(2)
        : Number(0.0).toFixed(2),
      created_at: reservation.booking_date,
    },
  ];

  return (
    <Document>
      <Page size="A4" orientation="landscape" style={styles.page}>
        <FolioTitle reservation={reservation} property={property} />
        <View style={styles.tableContainer}>
          <FolioTableHeader />
          <FolioTableRow items={reservationCharges} reservation={reservation} />
          <FolioTableFooter items={reservationCharges} />
        </View>
        <FolioFooter />
      </Page>

      <Page size="A4" orientation="landscape" style={styles.page}>
        <Text style={styles.title}>Extra Charges</Text>
        <View style={styles.tableContainer}>
          <FolioTableHeader />
          <FolioTableRow items={extraCharges} reservation={reservation} />
          <FolioTableFooter items={extraCharges} />
        </View>

        <Text style={styles.title}>Incidentals</Text>
        <View style={styles.tableContainer}>
          <FolioTableHeader />
          <FolioTableRow items={incidentals} reservation={reservation} />
          <FolioTableFooter items={incidentals} />
        </View>
        <View style={styles.tableContainer}>
          <FolioTotal
            reservation={reservationCharges}
            extraCharges={extraCharges}
            incidentals={incidentals}
          />
        </View>

        <FolioFooter />
      </Page>
    </Document>
  );
};

const styles = StyleSheet.create({
  page: {
    backgroundColor: "#fff",
    fontFamily: "Helvetica",
    fontSize: 12,
    padding: "20px",
    height: "100%",
    lineHeight: 1.5,
    flexDirection: "column",
  },
  logo: {
    width: 40,
    height: 40,
    marginLeft: "auto",
    marginRight: "auto",
  },
  tableContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 10,
    borderWidth: 0,
    borderColor: "#000",
  },
  title: {
    flexWrap: "wrap",
    marginTop: 24,
    fontSize: 18,
    fontWeight: 800,
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
  generatedDate: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 20,
    right: 0,
    textAlign: "left",
    color: "grey",
  },
});
