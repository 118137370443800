import { State } from "country-state-city";
import { CustomField } from "..";
import timezones from "timezones-list";

class Timezone {
	constructor(readonly code: string, readonly utc: string) {}

	toString(): string {
		return `${this.code} (${this.utc})`;
	}
}

const GeneralSection = () => {

	const timezoneLabels = timezones.map((timezone) => {
		return new Timezone(timezone.tzCode, timezone.utc);
	});

	return (
		<div className="property-creation-form-section">
			<h2>General</h2>
			<CustomField name="property_name" label="Property name" id="property_name" />
			<div
				style={{
					display: "grid",
					gridTemplateColumns: "1fr 1fr 1fr",
					gridGap: "2rem"
				}}
			>
				<CustomField name="address" label="Address" id="address" />
				<CustomField name="city" label="City" id="city" />
				<CustomField name="zip_code" label="Zip code" id="zip_code" />
			</div>
			<div
				style={{
					display: "grid",
					gridTemplateColumns: "1fr 1fr 1fr",
					gridGap: "2rem"
				}}
			>
				<CustomField name="country" label="Country" id="country" as="select">
					<option value=""></option>
					<option value="United States">United States</option>
					<option value="other">Other</option>
				</CustomField>

				<CustomField name="state" label="State" id="state" as="select">
					<option value=""></option>
					{State.getStatesOfCountry("US").map((item) => (
						<option key={item.name} value={item.name}>{item.name}</option>
					))}
				</CustomField>

				<CustomField name="timezone" label="Timezone" id="timezone" as="select">
					<option value=""></option>
					{timezoneLabels.map((timezone) => (
						<option key={timezone.code} value={timezone.code}>{timezone.toString()}</option>
					))}
				</CustomField>
			</div>

			<div
				style={{
					display: "grid",
					gridTemplateColumns: "1fr 1fr",
					gridGap: "2rem"
				}}
			>
				<CustomField name="email" label="Email" id="email" />
				<CustomField name="phone" label="Phone" id="phone" />
			</div>
			<CustomField
				name="terms_and_conditions"
				label="Terms and conditions"
				id="terms_and_conditions"
				as="textarea"
			/>
		</div>
	);
};

export default GeneralSection;
