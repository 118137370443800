import { createContext } from 'react';
import { Property } from '../domain/entities/property';

interface ContextProps {
    properties: Property[];
    // Methods
    getPropertiesByUser: () => Promise<void>;
    deleteProperty: (propertyId: number) => Promise<void>;
    updateProperty: (property: Property) => Promise<void>;
    createProperty: (property: Property) => Promise<void>;
    assignAdminsToProperty: (propertyId: number, adminIds: number[]) => Promise<void>;
    syncReservationsFromCloudBeds: (propertyId: number, fromDate: string) => Promise<void>;
}

export const PropertiesContext = createContext({} as ContextProps);
