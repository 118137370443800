import { ErrorMessage } from 'formik';
import { FC } from 'react';
import { NumericFormat } from 'react-number-format';

interface NumbericCustomFieldProps {
    label: string;
    type?: string;
    id: string;
    name: string;
    placeholder?: string;
    prefix?: string;
    suffix?: string;
    required?: boolean;
    disabled?: boolean;
    initialValue?: number;
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
}

const NumbericCustomField: FC<NumbericCustomFieldProps> = (
    {
        label,
        id,
        name,
        placeholder,
        prefix = "",
        suffix = "",
        required = true,
        disabled = false,
        initialValue = 0,
        setFieldValue
    }
) => {
    return <label
        id={id}
        htmlFor={id}
        className="property-creation-form-item"
    >
        <span data-required={required} id={id}>{label}</span>
        <NumericFormat
            id={id}
            name={name}
            displayType="input"
            prefix={prefix}
            suffix={suffix}
            value={initialValue}
            onValueChange={(values) => setFieldValue(id, values.floatValue, required)}
            decimalSeparator="."
            thousandsGroupStyle="lakh"
            thousandSeparator={true}
            disabled={disabled}
            placeholder={placeholder}
            required={required}
        />
        <ErrorMessage name={name} component="div" className="error-message" />
    </label>
}


export default NumbericCustomField;