import { FC } from 'react';
import CloseIcon from "src/app/assets/images/close-icon.svg";
import { useSideDrawerContext } from './context';
import './styles.scss';

interface Props {
  title: string;
  subtitle?: string;
  description?: string;
  children: React.ReactNode;
}

const SideDrawer: FC<Props> = ({ title, subtitle, description, children }) => {
  const { isDrawerOpened, toggleSideDrawer } = useSideDrawerContext();

  return (
    <div className="side-drawer" data-opened={isDrawerOpened} >
      <div className="side-drawer-header">
        <div className="side-drawer-header-title">
          <h2>{title}</h2>
        </div>
        <div className="side-drawer-header-actions">
          <div
            className="close-button"
            onClick={(e) => toggleSideDrawer()}
          >
            <img alt="" src={CloseIcon} width="24px" height="24px" />
          </div>
        </div>
      </div>
      <div className="side-drawer-subtitle">
        <p>
          {subtitle}
        </p>
      </div>
      <div className="side-drawer-description">
        <p>
          {description}
        </p>
      </div>
      <div className="side-drawer-body">
        {children}
      </div>
    </div>
  );
}

export default SideDrawer;
