import moment from "moment";
import { FC } from "react";
import DataTable, { TableColumn } from "react-data-table-component";
import Loader from "react-loader-spinner";
import { RoomLock } from "src/app/rooms-locks/domain/entities/room-lock";
import { ActionButtonTable } from "./ActionButtonTable";
import QRCodeIcon from "src/app/assets/images/qr-code.svg";

type RoomLockRowData = {
	lockId: string;
	battery: number;
	batteryText: string;
	signal: string;
	signalText: string;
	roomNumber: number;
	floorNumber: number;
	gateway: string;
	lastUpdate: string;
};

interface Props {
	isLoading: boolean;
	roomsLocks: RoomLock[];
	onGetQrPressed: (lockId: string) => void;
}

export const RoomsLocksTable: FC<Props> = ({ isLoading, roomsLocks, onGetQrPressed }) => {

	const tableData = roomsLocks.map((roomLock) => ({
		lockId: roomLock.lockId,
		battery: roomLock.battery,
		batteryText: `${roomLock.battery}%`,
		signal: `${roomLock.signal} dBm`,
		signalText: `${getSignalText(roomLock)}`,
		roomNumber: roomLock.roomNumber,
		floorNumber: roomLock.floorNumber,
		gateway: roomLock.gateway,
		lastUpdate: moment(roomLock.lastUpdate).format("MM/DD/YYYY HH:mm:ss")
	}));

	const columns: TableColumn<RoomLockRowData>[] = [
		{
			name: "Room",
			sortable: true,
			selector: (row) => row.roomNumber
		},
		{
			name: "Floor",
			sortable: true,
			selector: (row) => row.floorNumber
		},
		{
			name: "Lock ID",
			selector: (row) => row.lockId
		},
		{
			name: "Battery",
			sortable: true,
			selector: (row) => row.batteryText,
			conditionalCellStyles: [
				{
					when: (row) => row.battery >= 70,
					style: { color: "green" }
				},
				{
					when: (row) => row.battery >= 30 && row.battery < 70,
					style: { color: "orange" }
				},
				{
					when: (row) => row.battery < 30,
					style: { color: "red" }
				}
			]
		},
		{
			name: "Signal",
			sortable: true,
			selector: (row) => row.signal
		},
		{
			name: "Gateway",
			sortable: true,
			selector: (row) => row.gateway
		},
		{
			name: "Last update",
			sortable: true,
			selector: (row) => row.lastUpdate
		},
		{
			name: "Actions",
			selector: (row) => row.lockId,
			cell: (row) => (
				<div
					style={{
						display: "flex",
						alignItems: "start",
						justifyContent: "start",
						minWidth: "110px"
					}}
				>
					<ActionButtonTable
						icon={QRCodeIcon}
						callback={() => onGetQrPressed(row.lockId)}
					/>
				</div>
			)
		}
	];

	function getSignalText(roomLock: RoomLock) {
		if (roomLock.signal >= -30) {
			return "Amazing";
		} else if (roomLock.signal >= -67 && roomLock.signal < -30) {
			return "Very good";
		} else if (roomLock.signal >= -70 && roomLock.signal < -67) {
			return "Okay";
		} else if (roomLock.signal >= -80 && roomLock.signal < -70) {
			return "Not good";
		} else {
			return "Unusable";
		}
	}

	return (
		<div className="table-wrapper">
			<DataTable
				columns={columns}
				data={tableData}
				striped
				defaultSortAsc
				progressPending={isLoading}
				progressComponent={
					<>
						<Loader
							type="Oval"
							height={48}
							width={48}
							color="#edc44c"
							secondaryColor="#ecd9a0"
						/>
					</>
				}
				fixedHeader
				fixedHeaderScrollHeight="600px"
				pagination
			/>
		</div>
	);
};
