import { AdminsState } from '.';
import { Admin } from '../domain/entities/admin';

type AdminsActionType =
    | { type: 'GET_ALL', payload: { admins: Admin[] } }

export const adminsReducer = (state: AdminsState, action: AdminsActionType): AdminsState => {

    switch (action.type) {
        case 'GET_ALL':
            const { admins } = action.payload;
            return {
                ...state,
                admins: [...admins]
            }

        default:
            return state;
    }
}
