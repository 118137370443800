import React from "react";
import { Redirect, withRouter } from "react-router-dom";
import "./styles.scss";
import { DashboardWrapper } from "../Dashboard/Components/DashboardWrapper";
import { hasValidRole } from "src/app/common/utils/sessionUtils";

export const PrivateRoute = (props) => {
  const { component, requiredRoles } = props;
  const ComponentWithRouterProps = withRouter(component);

  return (
    <React.Fragment>
      {
        hasValidRole(requiredRoles) ? (
          <DashboardWrapper>
            <ComponentWithRouterProps params={props.computedMatch.params} />
          </DashboardWrapper>
        ) : (
          <Redirect to="/" />
        )}
    </React.Fragment>
  );
};
