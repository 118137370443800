import { Property } from "src/app/properties/domain/entities/property";
import { bool, number, object, string } from "yup";

export const ValidationSchema = object().shape({
    property_name: string().required("Required"),
    email: string()
        .required("Valid email required")
        .email("Valid email required"),
    phone: string().required("Required"),
    country: string().required("Required"),
    state: string().required("Required"),
    city: string().required("Required"),
    address: string().required("Required"),
    terms_and_conditions: string().required("Required"),
    checkin_time: string().required("Required"),
    checkout_time: string().required("Required"),
    late_checkout_time: string().required("Required"),
    late_checkout_notification_time: string().required("Required"),
    late_checkout_amount: string().required("Required"),
    cloudbeds: bool(),
    api_user_key: string().when("cloudbeds", {
        is: true,
        then: string().required("Required"),
    }),
    api_user_pwd: string().when("cloudbeds", {
        is: true,
        then: string().required("Required"),
    }),
    pms_property_id: string().when("cloudbeds", {
        is: true,
        then: string().required("Required"),
    }),
    send_key_automatically: bool(),
    send_key_time: string().when("send_key_automatically", {
        is: true,
        then: string().required("Required"),
    }),
    stripe_public_token: string().required("Required"),
    stripe_secret_token: string().required("Required"),
    hold_amount: number().required("Required").typeError('Must be a number'),
    release_days: number().required("Required").typeError('Must be a number'),
    release_max_days: number().required("Required").typeError('Must be a number'),
    city_tax: number().required("Required").typeError('Must be a percentage. i.e: 1.5'),
    occupancy_tax: number().required("Required").typeError('Must be a percentage. i.e: 14.2'),
    zip_code: string().required("Required"),
    amenity_fee: number().required("Required").typeError('Must be a number'),
    qr_master_account_id: string().required("Required"),
    qr_master_auth_token: string().required("Required"),
    qr_master_community_no: string().required("Required"),
    timezone: string().required("Required"),
    qr_master_area_code: string().required("Required"),
    qr_master_phone_number: string().required("Required"),
});

export const mockData: Property = {
    address: "4455 Paradise Rd. Las Vegas, NV 89169. Eastside.",
    amenity_fee: 25,
    pms_property_id: "13545",
    api_user_key: "API_USER_KEY",
    api_user_pwd: "API_USER_PWD",
    building_no: "252",
    checkin_time: "16:00",
    checkout_time: "11:00",
    city: "Las Vegas",
    city_tax: 1.5,
    cloudbeds: true,
    country: "United States",
    timezone: "America/Los_Angeles",
    email: "mtc.morethancode@gmail.com",
    hold_amount: 250,
    late_checkout_amount: "5",
    late_checkout_notification_time: 2,
    late_checkout_time: "13:00",
    occupancy_tax: 14.6,
    phone: "+15551234555",
    property_name: "MTC HOTEL",
    release_days: 1,
    release_max_days: 6,
    send_key_automatically: false,
    send_key_time: "16:00",
    state: "Nevada",
    stripe_account_id: "STRIPE_ACCOUNT_ID",
    stripe_public_token: "STRIPE_PUBLIC_TOKEN",
    stripe_secret_token: "STRIPE_SECRET_TOKEN",
    terms_and_conditions: "Aute nostrud qui cillum dolore nostrud do officia qui ipsum qui Lorem tempor qui proident.",
    zip_code: "01550",
    qr_master_account_id: "QR_MASTER_ACCOUNT_ID",
    qr_master_auth_token: "QR_MASTER_AUTH_TOKEN",
    qr_master_community_no: "132465789",
    qr_master_area_code: "54",
    qr_master_phone_number: "11223344"
}

// Es necesario para que construya correctamente el json de salida. sino los campos vacios no los agrega al body del post y rompe.
export const initialValues: Property = {
    address: "",
    amenity_fee: 0,
    pms_property_id: "",
    api_user_key: "",
    api_user_pwd: "",
    building_no: "",
    checkin_time: "",
    checkout_time: "",
    city: "",
    city_tax: 0.0,
    cloudbeds: false,
    country: "",
    timezone: "",
    email: "",
    hold_amount: 0,
    late_checkout_amount: "",
    late_checkout_notification_time: 0,
    late_checkout_time: "",
    occupancy_tax: 0.0,
    phone: "",
    property_name: "",
    release_days: 0,
    release_max_days: 0,
    send_key_automatically: false,
    send_key_time: "",
    state: "",
    stripe_account_id: "",
    stripe_public_token: "",
    stripe_secret_token: "",
    terms_and_conditions: "",
    zip_code: "",
    qr_master_account_id: "",
    qr_master_auth_token: "",
    qr_master_community_no: "",
    qr_master_area_code: "",
    qr_master_phone_number: ""
};