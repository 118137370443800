import { createContext } from "react";
import { RoomLock } from "../domain/entities/room-lock";

interface ContextProps {
	roomsLocks: Map<number, RoomLock[]>;
	// Methods
	fetchRoomsLocks: (propertyId: number) => Promise<void>;
	getConfigQr: (lockId: string) => Promise<string>;
}

export const RoomsLocksContext = createContext({} as ContextProps);
