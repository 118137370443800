import { FC, useEffect, useState } from "react";
import DataTable, { TableColumn } from "react-data-table-component";
import { useAdmins } from "src/app/admins/hooks/useAdmins";
import { AppButton } from "src/app/components/AppButton";
import OverlayLoaderSuccessError from "src/app/components/OverlayLoaderSuccessError/OverlayLoaderSuccessError";
import { useSideDrawerContext } from "src/app/components/SideDrawer/context";
import { useProperties } from "src/app/properties/hooks/useProperties";

enum ViewStatus {
	INITIAL = "INITIAL",
	LOADING = "LOADING",
	ERROR = "ERROR",
	SUCCESS = "SUCCESS"
}

type TableAdmin = {
	id: number;
	name: string;
	email: string;
	isSelected: boolean;
	isDisabled: boolean;
};

interface Props {
	propertyId: number;
	assignedAdmins: number[];
}

const selectedTableAdmins = (tableAdmin: TableAdmin) => tableAdmin.isSelected;

const AssignAdminsContainer: FC<Props> = ({ propertyId, assignedAdmins }) => {
	const { toggleSideDrawer } = useSideDrawerContext();
	const { admins, getAdmins } = useAdmins();
	const { assignAdminsToProperty } = useProperties();

	const [viewStatus, setViewStatus] = useState<ViewStatus>(ViewStatus.LOADING);
	const [tableAdmins, setTableAdmins] = useState<TableAdmin[]>([]);

	const [selectedAdmins, setSelectedAdmins] = useState<number[]>(assignedAdmins);

	const columns: TableColumn<TableAdmin>[] = [
		{
			name: "Name",
			selector: (admin) => admin.name,
			sortable: true
		},
		{
			name: "Email",
			selector: (admin) => admin.email,
			sortable: true
		}
	];

	useEffect(() => {
		if (propertyId === -1) return;
		setTableAdmins([]);
		setViewStatus(ViewStatus.LOADING);

		getAdmins()
			.then(() => setViewStatus(ViewStatus.INITIAL))
			.catch((e) => setViewStatus(ViewStatus.ERROR));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [propertyId]);

	useEffect(() => {
		if (admins.length === 0) return;

		const tableAdmins = admins.map((admin) => ({
			id: admin.id!,
			name: admin.name!,
			email: admin.email!,
			isSelected: assignedAdmins.includes(admin.id!),
			isDisabled: "root" === admin.role
		}));
		setTableAdmins(tableAdmins);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [admins]);

	const onSelectedAdminsChange = (selectedTableAdmins: TableAdmin[]) => {
		const selectedAdminIds = selectedTableAdmins.map((admin) => admin.id);
		setSelectedAdmins(selectedAdminIds);
	};

	const handleOnSubmit = async () => {
		try {
			setViewStatus(ViewStatus.LOADING);

			await assignAdminsToProperty(propertyId, selectedAdmins);

			setTimeout(() => {
				setViewStatus(ViewStatus.SUCCESS);
			}, 1000);

			setTimeout(() => {
				toggleSideDrawer();
				setViewStatus(ViewStatus.INITIAL);
			}, 3000);
		} catch (ex) {
			console.log(ex);
		}
	};

	return (
		<div
			style={{
				display: "flex",
				flexDirection: "column",
				justifyContent: "space-between",
				width: "100%",
				height: "100%",
				gap: "10px"
			}}
		>
			<OverlayLoaderSuccessError
				isLoading={viewStatus === ViewStatus.LOADING}
				isSuccessful={viewStatus === ViewStatus.SUCCESS}
				isError={viewStatus === ViewStatus.ERROR}
				message={
					viewStatus === ViewStatus.SUCCESS
						? "Administrators assigned successfully"
						: viewStatus === ViewStatus.ERROR
						? "Error assigning administrators"
						: ""
				}
			/>
			<DataTable
				striped
				defaultSortFieldId={1}
				columns={columns}
				data={tableAdmins}
				selectableRows
				selectableRowDisabled={(row) => row.isDisabled}
				selectableRowSelected={selectedTableAdmins}
				onSelectedRowsChange={(selected) => onSelectedAdminsChange(selected.selectedRows)}
				fixedHeader
				pagination
				fixedHeaderScrollHeight="calc(100vh - 200px)"
			/>
			<div className="side-drawer-footer">
				<AppButton label="Accept" onClick={() => handleOnSubmit()} />
			</div>
		</div>
	);
};

export default AssignAdminsContainer;
