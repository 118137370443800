import { PropertiesState } from '.';
import { Property } from '../domain/entities/property';

type PropertiesActionType =
    | { type: 'GET_PROPERTIES_BY_USER', payload: { properties: Property[] } }
    | { type: 'CREATE_PROPERTY', payload: { property: Property } }
    | { type: 'DELETE_PROPERTY', payload: { propertyId: number } }
    | { type: 'UPDATE_PROPERTY', payload: { updatedProperty: Property } }
    | { type: 'ASSIGN_ADMINS_TO_PROPERTY', payload: { propertyId: number, adminIds: number[] } }

export const propertiesReducer = (state: PropertiesState, action: PropertiesActionType): PropertiesState => {

    switch (action.type) {
        case 'GET_PROPERTIES_BY_USER':
            const { properties } = action.payload;
            return {
                ...state,
                properties: [...properties]
            }

        case 'CREATE_PROPERTY':
            const { property } = action.payload;
            return {
                ...state,
                properties: [...state.properties, property]
            }

        case 'DELETE_PROPERTY':
            const { propertyId } = action.payload;
            const nonDeletedPlots = state.properties.filter(property => property.id !== propertyId);

            return {
                ...state,
                properties: [...nonDeletedPlots]
            };

        case 'UPDATE_PROPERTY':
            const { updatedProperty } = action.payload;
            const updatedProperties = state.properties.map(p => {
                if (p.id !== updatedProperty.id) return p;
                return updatedProperty;
            })

            return {
                ...state,
                properties: [...updatedProperties]
            }

        case 'ASSIGN_ADMINS_TO_PROPERTY':
            const updatedPropertyWithAdminsIds = state.properties.map(p => {
                if (p.id !== action.payload.propertyId) return p;
                return { ...p, admins: [...action.payload.adminIds] };
            })

            return {
                ...state,
                properties: [...updatedPropertyWithAdminsIds]
            }

        default:
            return state;
    }
}
