import { createContext } from 'react';
import { Admin } from '../domain/entities/admin';

interface ContextProps {
    admins: Admin[];
    // Methods
    getAdmins: () => Promise<void>;
}

export const AdminsContext = createContext({} as ContextProps);
