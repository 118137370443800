import { FC } from "react";
import { Property } from "src/app/properties/domain/entities/property";
import { CustomField } from "..";
import NumbericCustomField from "../NumbericCustomField";

interface PaymentsSectionProps {
	values: Property;
	setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
}

const PaymentsSection: FC<PaymentsSectionProps> = ({ values, setFieldValue }) => (
	<div className="property-creation-form-section">
		<h2>Payments</h2>

		<div
			style={{
				display: "grid",
				gridTemplateColumns: "1fr 1fr",
				gridGap: "0 2rem"
			}}
		>
			<CustomField
				id="stripe_account_id"
				label="Stripe Account ID"
				name="stripe_account_id"
			/>
			<CustomField
				id="stripe_public_token"
				label="Stripe Public Token"
				name="stripe_public_token"
			/>
			<CustomField
				id="stripe_secret_token"
				label="Stripe Secret Token"
				name="stripe_secret_token"
			/>
		</div>
		<div
			style={{
				display: "grid",
				gridTemplateColumns: "1fr 1fr",
				gridGap: "0 2rem"
			}}
		>
			<CustomField
				id="hold_amount"
				label="Hold amount"
				name="hold_amount"
				placeholder="$0.00"
			/>
			<CustomField
				id="amenity_fee"
				label="Amenity fee"
				name="amenity_fee"
				type="number"
				placeholder="$0.00"
			/>
			<CustomField
				id="release_days"
				label="Release days"
				name="release_days"
				placeholder="E.g.: 1"
			/>
			<CustomField
				id="release_max_days"
				label="Release max days"
				name="release_max_days"
				placeholder="E.g.: 6"
			/>
			<NumbericCustomField
				id="city_tax"
				name="city_tax"
				label="City tax (%)"
				suffix="%"
				placeholder="0.0%"
				initialValue={values?.city_tax ?? 0}
				setFieldValue={(field, value, shouldValidate) => setFieldValue(field, value, shouldValidate)}
			/>
			<NumbericCustomField
				id="occupancy_tax"
				label="Occupancy tax (%)"
				name="occupancy_tax"
				suffix="%"
				placeholder="0.0%"
				initialValue={values?.occupancy_tax ?? 0}
				setFieldValue={(field, value, shouldValidate) => setFieldValue(field, value, shouldValidate)}
			/>
		</div>
	</div>
);

export default PaymentsSection;
