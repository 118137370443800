import { FC } from 'react';
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa';
import Loader from 'react-loader-spinner';
import './styles.scss';

interface Props {
    isLoading: boolean;
    isSuccessful: boolean;
    isError: boolean;
    message?: string;
}

interface PropsContainer {
    message: string;
}

const OverlayLoaderSuccessError: FC<Props> = ({ isLoading, isSuccessful, isError, message = "" }) => {

    return (
        <div className='overlay-loader-main-cont' data-visible={isLoading || isSuccessful || isError}>
            {
                isSuccessful ?
                    <SuccessContainer message={message} /> :
                    isError ?
                        <ErrorContainer message={message} />
                        :
                        <Loader type="Oval" color="#030303" height={50} width={50} />
            }
        </div>
    )
}

const SuccessContainer: FC<PropsContainer> = ({ message }) => {
    return (
        <div className="right-wrapper">
            <div className="tabs-wrapper property-creation-cont">
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: '1rem',
                    }}
                >
                    <FaCheckCircle color="#FFD865" size="2rem" />
                    <h4>
                        {message}
                    </h4>
                </div>
            </div>
        </div>
    );
}

const ErrorContainer: FC<PropsContainer> = ({ message }) => {
    return (
        <div className="right-wrapper">
            <div className="tabs-wrapper property-creation-cont">
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: '1rem',
                    }}
                >
                    <FaTimesCircle color="red" size="2rem" />
                    <h4>
                        {message}
                    </h4>
                </div>
            </div>
        </div>
    );
}

export default OverlayLoaderSuccessError;
