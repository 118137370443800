import { Formik } from "formik";
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import OverlayLoaderSuccessError from "src/app/components/OverlayLoaderSuccessError/OverlayLoaderSuccessError";
import { Property } from "src/app/properties/domain/entities/property";
import { useProperties } from "src/app/properties/hooks/useProperties";
import { PropertyForm } from "../components/form/PropertyForm";
import { ValidationSchema } from "../utils/constants";

enum ViewStatus {
	INITIAL = "INITIAL",
	LOADING = "LOADING",
	ERROR = "ERROR",
	SUCCESS = "SUCCESS"
}

const DetailsProperty = () => {
	const [status, setStatus] = useState<ViewStatus>(ViewStatus.INITIAL);
	const { updateProperty, properties } = useProperties();
	const [initialValues, setInitialValues] = useState<Property>();
	const history = useHistory();
	const location = useLocation();

	const getPropertyId = (): number | null => {
		const propertyId = location.pathname.split("/").at(-1);
		if (!propertyId || typeof propertyId === 'number') throw new Error("Invalid property id");

		return parseInt(propertyId!);
	};

	const getPropertyForUpdate = (): Property | null => {
		const propertyId = getPropertyId();
		if (!propertyId) return null;
		return properties.find((property) => property.id === propertyId) ?? null;
	};

	useEffect(() => {
		try {
			if (getPropertyId() !== null) {
				const property = getPropertyForUpdate();
				if (property != null) {
					setInitialValues(property);
				}
			}
		} catch (error) {
			goToProperties();
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [initialValues]);

	const goToProperties = () => {
		history.push("/properties");
	};

	const handleSubmit = async (values: any) => {
		setStatus(ViewStatus.LOADING);
		try {
			await updateProperty(values);
			setStatus(ViewStatus.SUCCESS);
			setTimeout(() => {
				setStatus(ViewStatus.INITIAL);
				goToProperties();
			}, 2000);
		} catch (error) {
			setStatus(ViewStatus.ERROR);
			setTimeout(() => {
				setStatus(ViewStatus.INITIAL);
			}, 2000);
		}
	};

	return (
		<div className="right-wrapper">
			<div className="tabs-wrapper property-creation-cont">
				<OverlayLoaderSuccessError
					isLoading={status === ViewStatus.LOADING}
					isSuccessful={status === ViewStatus.SUCCESS}
					isError={status === ViewStatus.ERROR}
					message={
						status === ViewStatus.SUCCESS
							? "Property updated successfully"
							: status === ViewStatus.ERROR
								? "Error updating property.\nPlease try again."
								: ""
					}
				/>
				<h1>Property creation</h1>
			</div>
			<Formik
				initialValues={initialValues!}
				onSubmit={handleSubmit}
				validationSchema={ValidationSchema}
				enableReinitialize
			>
				{
					props => {
						const {
							values,
							touched,
							errors,
							dirty,
							isSubmitting,
							handleChange,
							setFieldValue,
							handleBlur,
							handleSubmit,
							handleReset,
						} = props;
						return (
							<PropertyForm id="update" labelButton="Update" values={values} setFieldValue={setFieldValue} />
						)
					}}
			</Formik>
		</div>
	);
};

export default DetailsProperty;
