import React, { useEffect, useState } from "react";
import { useDispatch, useSelector, connect } from "react-redux";
import { Auth } from "aws-amplify";
import "./styles.scss";
import { AppButton } from "src/app/components/AppButton";
import DatePicker from "sassy-datepicker";
import moment from "moment";
import { setWsMessageReceivedStatus } from "src/app/pages/Dashboard/actionCreators";
import { isValidPhoneNumber } from "react-phone-number-input";
import { validateEmail } from "./helper";
import { SERVICES } from "./constants";
import Select from "react-select";
import CloseIcon from "src/app/assets/images/close-icon.svg";
import { TextField } from "src/app/components/TextField";
import { PhoneField } from "src/app/components/PhoneField";
import { CalendarIcon } from "src/app/components/CalendarIcon";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "src/app/components/CheckoutForm";
import { SuccessIcon } from "src/app/components/SuccessIcon";
import { confirmAlert } from "react-confirm-alert";
import { SkeletonLoader } from "src/app/components/SkeletonLoader";
import Loader from "react-loader-spinner";
import {
	getCloudBedsRoomsService,
	createReservationService,
	getReservationDataService,
	updateReservationService,
	getAvailableRoomTypesService,
	getStripePublicTokenService,
	refundReservationService,
	getRatePlansService,
	chargeReservationAutomaticallyService,
	updateReservationStatusToConfirmedService,
	updatePaymentAmountService,
	createNoteService,
	getChangeReasonsService,
	getPropertyDetailsService,
	getRoomTypesService
} from "./services.js";

export const ReservationManagement = ({
	open,
	setOpen,
	reservationId = null,
	edit = false,
	refreshReservationDetails
}) => {
	const { selectedProperty } = useSelector((state) => state.reservations);
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(false);
	const [processing, setProcessing] = useState(false);
	const [loadingRooms, setLoadingRooms] = useState(false);
	const [loadingAvailableRoomTypes, setLoadingAvailableRoomTypes] = useState(false);
	const [loadingRatePlans, setLoadingRatePlans] = useState(false);
	// eslint-disable-next-line
	const [error, setError] = useState(false);
	const [showErrorMessage, setShowErrorMessage] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");
	const [guestName, setGuestName] = useState(null);
	const [guestEmail, setGuestEmail] = useState(null);
	const [guestPhoneNumber, setGuestPhoneNumber] = useState(null);
	const [checkInDate, setCheckInDate] = useState(moment(new Date()).toDate());
	const [checkInDateVisible, setCheckInDateVisible] = useState(false);
	const [checkOutDate, setCheckOutDate] = useState(moment(new Date()).add(1, "days").toDate());
	const [checkOutDateVisible, setCheckOutDateVisible] = useState(false);
	const [rooms, setRooms] = useState([]);
	const [room, setRoom] = useState(null);
	const [availableRoomTypes, setAvailableRoomTypes] = useState([]);
	const [roomType, setRoomType] = useState(null);
	const [ratePlans, setRatePlans] = useState([]);
	const [ratePlan, setRatePlan] = useState(null);
	const [reservationData, setReservationData] = useState(null);
	const [propertyDetails, setPropertyDetails] = useState(null);
	// eslint-disable-next-line
	const [statusOptions, setStatusOptions] = useState([
		{ value: "confirmed", label: "Confirmed" },
		{ value: "not_confirmed", label: "Not-confirmed" },
		{ value: "canceled", label: "Canceled" },
		{ value: "checked_in", label: "Checked-in" },
		{ value: "checked_out", label: "Checked-out" },
		{ value: "no_show", label: "No-show" }
	]);
	const [status, setStatus] = useState(null);
	const [roomChangeReasons, setRoomChangeReasons] = useState([]);
	const [roomChangeReason, setRoomChangeReason] = useState(null);
	const [stripeToken, setStripeToken] = useState(null);
	const [screen, setScreen] = useState("RESERVATION");
	const [subTotal, setSubtotal] = useState(0);
	const [cityTaxAmount, setCityTaxAmount] = useState(0);
	const [occupancyTaxAmount, setOccupancyTaxAmount] = useState(0);
	const [grandTotal, setGrandTotal] = useState(0);
	const [amenityFee, setAmenityFee] = useState(0);
	const [otherFeesAmount, setOtherFeesAmount] = useState(0);
	// eslint-disable-next-line
	const [requireRefund, setRequireRefund] = useState(false);
	const [requireCharge, setRequireCharge] = useState(false);
	const [disableCharge, setDisableCharge] = useState(false);
	const [paymentDescription, setPaymentDescription] = useState(null);
	const [paymentTitle, setPaymentTitle] = useState("Reservation Payment");
	const [paymentButton, setPaymentButton] = useState("Charge");

	const ELEMENTS_OPTIONS = {
		fonts: [
			{
				cssSrc: "https://fonts.googleapis.com/css?family=Roboto"
			}
		]
	};

	useEffect(() => {
		if (open && selectedProperty) {
			document.getElementById("app-body").style.overflow = "hidden";
			getStripePublicToken();
			getPropertyDetails();

			if (reservationId) {
				getReservationData(reservationId);
			}
			if (edit) {
				getChangeReasons();
			}
		}
		if (!open && !edit) {
			document.getElementById("app-body").style.overflow = "auto";
		}
		// eslint-disable-next-line
	}, [open]);

	useEffect(() => {
		if (edit) {
			if (open && reservationData) {
				getRoomTypes(
					moment(reservationData.checkin_date).toDate(),
					moment(reservationData.checkout_date).add(1, "days").toDate()
				);
			}
		} else {
			getAvailableRoomTypes(
				moment(new Date()).toDate(),
				moment(new Date()).add(1, "days").toDate()
			);
		}

		// eslint-disable-next-line
	}, [open, reservationData]);

	useEffect(() => {
		if (open && propertyDetails && subTotal) {
			calculateReservationTotal();
		}
		// eslint-disable-next-line
	}, [open, propertyDetails, subTotal]);

	useEffect(() => {
		if (open && reservationData && grandTotal) {
			if (
				reservationData &&
				grandTotal > 0 &&
				grandTotal !== reservationData.reservation_payment_amount
			) {
				if (reservationData.reservation_payment_id) {
					setRequireRefund(true);
				}
				setRequireCharge(true);
			} else {
				setRequireRefund(false);
				if (!reservationData.reservation_payment_id) {
					setRequireCharge(true);
				} else {
					setRequireCharge(false);
				}
			}
		}
		// eslint-disable-next-line
	}, [open, grandTotal]);

	const calculateReservationTotal = async () => {
		const { city_tax, occupancy_tax, amenity_fee } = propertyDetails;

		let amenityFeeValue = 0;
		let cityTaxAmountValue = 0;
		let occupancyTaxAmountValue = 0;

		cityTaxAmountValue = Number(subTotal) * Number(city_tax);
		const cityTaxAmountRounded = Math.round((cityTaxAmountValue + Number.EPSILON)) / 100;

		setCityTaxAmount(cityTaxAmountRounded);

		occupancyTaxAmountValue = Number(subTotal) * Number(occupancy_tax);
		const occupancyTaxRounded = Math.round((occupancyTaxAmountValue + Number.EPSILON)) / 100;

		setOccupancyTaxAmount(occupancyTaxRounded.toFixed(2));

		if (subTotal > 0) {
			const checkin_data_object = new Date(checkInDate);
			const checkout_data_object = new Date(checkOutDate);

			const diffTime = Math.abs(
				checkout_data_object.getTime() - checkin_data_object.getTime()
			);

			const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

			amenityFeeValue = amenity_fee * diffDays;
		} else {
			amenityFeeValue = 0;
		}

		setAmenityFee(amenityFeeValue.toFixed(2));
		setOtherFeesAmount((0.0).toFixed(2));

		const total =
			Number(subTotal) +
			Number(cityTaxAmountRounded) +
			Number(occupancyTaxRounded) +
			Number(amenityFeeValue);

		setGrandTotal(total.toFixed(2));
	};

	const getStripePublicToken = async () => {
		setLoading(true);
		try {
			const { apiEndpoint } = SERVICES.getStripePublicToken;
			const propertyId = selectedProperty.value;

			const response = await getStripePublicTokenService(apiEndpoint, propertyId);

			const stripeToken = loadStripe(response.stripePublicToken);
			setStripeToken(stripeToken);
		} catch (error) {
			console.log(error);
		} finally {
			setLoading(false);
		}
	};

	const handleCancel = () => {
		setScreen("RESERVATION");
		closeModal();
	};

	const handlePaymentScreen = (title, button) => {
		setPaymentTitle(title);
		setPaymentButton(button);
		setScreen("PAYMENT");
	};

	const clearFields = () => {
		setErrorMessage("");
		setError(false);
		setGuestName("");
		setGuestEmail("");
		setGuestPhoneNumber("");
		setSubtotal(0);
		setCityTaxAmount(0);
		setOccupancyTaxAmount(0);
		setAmenityFee(0);
		setOtherFeesAmount(0);
		setAvailableRoomTypes([]);
		setRatePlans([]);
		setRooms([]);
		setRoomType(null);
		setRatePlan(null);
		setRoom(null);
		setRoomChangeReason(null);
		setPaymentDescription(null);
		setCheckInDate(moment(new Date()).toDate());
		setCheckOutDate(moment(new Date()).add(1, "days").toDate());
	};

	const closeModal = async () => {
		if (refreshReservationDetails) {
			await refreshReservationDetails();
		}
		setScreen("RESERVATION");
		setOpen(false);
		clearFields();
	};

	const getCloudBedsRooms = async (roomTypeId = null, checkIn = null, checkOut = null) => {
		const { apiEndpoint } = SERVICES.getCloudBedsRooms;
		setLoadingRooms(true);
		setRoom(null);
		setRooms([]);

		try {
			const response = await getCloudBedsRoomsService(apiEndpoint, {
				propertyId: selectedProperty.value,
				roomTypeId: roomTypeId,
				checkInDate: moment(checkIn).format("YYYY-MM-DD").toString(),
				checkOutDate: moment(checkOut).format("YYYY-MM-DD").toString()
			});
			if (response) {
				const items = response.data[0].rooms.map((item, i) => {
					const room = {
						id: item.roomID,
						value: item.roomName,
						label: `${item.roomName} - ${item.roomTypeName}`,
						roomTypeID: item.roomTypeID,
						roomId: item.roomID,
						roomTypeName: item.roomTypeName,
						roomName: item.roomName,
						roomBlocked: item.roomBlocked
					};
					return room;
				});

				const sortedItems = items.sort((a, b) => a.value.localeCompare(b.value));

				const availableRooms = sortedItems.filter((item) => item.roomBlocked === false);
				setRooms(availableRooms);

				if (edit && sortedItems && reservationData) {
					// eslint-disable-next-line
					const defaultValue = sortedItems.filter(
						(item) => item.roomName === reservationData.room_no
					);

					setRoom(reservationData.room_no);
				}
			} else {
				setRooms([]);
			}
		} catch (error) {
			handleError();
		} finally {
			setLoadingRooms(false);
		}
	};

	const getAvailableRoomTypes = async (checkIn = null, checkOut = null) => {
		const { apiEndpoint } = SERVICES.getAvailableRoomTypes;
		setLoadingAvailableRoomTypes(true);

		setAvailableRoomTypes([]);
		setRatePlans([]);
		setRooms([]);

		setRoomType(null);
		setRatePlan(null);
		setRoom(null);

		try {
			const response = await getAvailableRoomTypesService(apiEndpoint, {
				propertyId: selectedProperty.value,
				checkInDate: moment(checkIn).format("YYYY-MM-DD").toString(),
				checkOutDate: moment(checkOut).format("YYYY-MM-DD").toString()
			});
			if (response.data && response.count > 0) {
				const items = response.data[0].propertyRooms.map((item, i) => {
					const roomTypeItem = {
						value: item.roomTypeID,
						label: `${item.roomTypeName}`,
						roomTypeID: item.roomTypeID,
						roomRate: item.roomRate,
						roomRateID: item.roomRateID,
						roomTypeName: item.roomTypeName
					};
					return roomTypeItem;
				});

				const sortedItems = items.sort((a, b) => a.label.localeCompare(b.label));

				const cleanedItems = sortedItems.filter((obj, pos, arr) => {
					return arr.map((mapObj) => mapObj.roomTypeID).indexOf(obj.roomTypeID) === pos;
				});

				setAvailableRoomTypes(cleanedItems);

				if (edit && cleanedItems && reservationData) {
					const defaultValue = cleanedItems.filter(
						(item) => item.label === reservationData.room_type_name
					);

					if (defaultValue && defaultValue.length >= 0) {
						setRoomType(defaultValue[0]);

						await getRatePlans(defaultValue[0].roomTypeID, checkIn, checkOut);
						await getCloudBedsRooms(defaultValue[0].roomTypeID, checkIn, checkOut);
					}
				}
			} else {
				setAvailableRoomTypes([]);
			}
		} catch (error) {
			handleError();
		} finally {
			setLoadingAvailableRoomTypes(false);
		}
	};

	const getRoomTypes = async (checkIn = null, checkOut = null) => {
		const { apiEndpoint } = SERVICES.getRoomTypes;
		setLoadingAvailableRoomTypes(true);

		setAvailableRoomTypes([]);
		setRatePlans([]);
		setRooms([]);

		setRoomType(null);
		setRatePlan(null);
		setRoom(null);

		try {
			const response = await getRoomTypesService(apiEndpoint, {
				propertyId: selectedProperty.value
			});
			if (response.data && response.count > 0) {
				const items = response.data.map((item, i) => {
					const roomTypeItem = {
						value: item.roomTypeID,
						label: `${item.roomTypeName}`,
						roomTypeID: item.roomTypeID,
						roomTypeName: item.roomTypeName
					};
					return roomTypeItem;
				});

				const sortedItems = items.sort((a, b) => a.label.localeCompare(b.label));

				const cleanedItems = sortedItems.filter((obj, pos, arr) => {
					return arr.map((mapObj) => mapObj.roomTypeID).indexOf(obj.roomTypeID) === pos;
				});

				setAvailableRoomTypes(cleanedItems);

				if (edit && cleanedItems && reservationData) {
					const defaultValue = cleanedItems.filter(
						(item) => item.label === reservationData.room_type_name
					);

					if (defaultValue && defaultValue.length >= 0) {
						setRoomType(defaultValue[0]);

						await getRatePlans(defaultValue[0].roomTypeID, checkIn, checkOut);
						await getCloudBedsRooms(defaultValue[0].roomTypeID, checkIn, checkOut);
					}
				}
			} else {
				setAvailableRoomTypes([]);
			}
		} catch (error) {
			handleError();
		} finally {
			setLoadingAvailableRoomTypes(false);
		}
	};

	const getChangeReasons = async () => {
		const { apiEndpoint } = SERVICES.getChangeReasons;
		setLoading(true);

		setRoomChangeReason(null);
		setRoomChangeReasons([]);

		try {
			const response = await getChangeReasonsService(apiEndpoint, {
				propertyId: selectedProperty.value
			});
			if (response) {
				const items = response.map((item, i) => {
					const reason = {
						id: item.id,
						value: item.description,
						label: item.description
					};
					return reason;
				});

				setRoomChangeReasons(items);
			} else {
				setRoomChangeReasons([]);
			}
		} catch (error) {
			handleError();
		} finally {
			setLoading(false);
		}
	};

	const getPropertyDetails = async () => {
		const { apiEndpoint } = SERVICES.getPropertyDetails;
		setLoading(true);

		setPropertyDetails(null);

		try {
			const response = await getPropertyDetailsService(apiEndpoint, {
				propertyId: selectedProperty.value
			});
			if (response) {
				setPropertyDetails(response);
			} else {
				setPropertyDetails([]);
			}
		} catch (error) {
			handleError();
		} finally {
			setLoading(false);
		}
	};

	const getRatePlans = async (roomTypeId = null, checkIn = null, checkOut = null) => {
		const { apiEndpoint } = SERVICES.getRatePlans;
		setLoadingRatePlans(true);

		setRatePlans([]);
		setRooms([]);

		setRatePlan(null);
		setRoom(null);

		try {
			const response = await getRatePlansService(apiEndpoint, {
				propertyId: selectedProperty && selectedProperty.value,
				roomTypeId: roomTypeId,
				checkInDate: moment(checkIn).format("YYYY-MM-DD").toString(),
				checkOutDate: moment(checkOut).format("YYYY-MM-DD").toString()
			});
			if (response.data) {
				const items = response.data.map((item, i) => {
					const ratePlanItem = {
						value: item.rateID,
						label: item.ratePlanNamePublic
							? `${item.ratePlanNamePublic} - $${item.totalRate.toFixed(2)}`
							: `${item.roomTypeName} - $${item.totalRate.toFixed(2)}`,
						roomTypeID: item.roomTypeID,
						roomRate: item.roomRate,
						totalRate: item.totalRate,
						rateID: item.rateID,
						roomTypeName: item.roomTypeName,
						ratePlanNamePublic: item.ratePlanNamePublic
							? item.ratePlanNamePublic
							: item.roomTypeName
					};
					return ratePlanItem;
				});
				// order alphabetically
				const sortedItems = items.sort((a, b) => a.label.localeCompare(b.label));

				setRatePlans(sortedItems);

				if (edit && sortedItems && reservationData) {
					let defaultValue = null;

					if (reservationData && reservationData.room_rate_plan) {
						defaultValue = sortedItems.filter(
							(item) => item.ratePlanNamePublic === reservationData.room_rate_plan
						);
					} else {
						defaultValue = sortedItems.filter(
							(item) => item.ratePlanNamePublic === reservationData.room_type_name
						);
					}
					if (defaultValue && defaultValue.length >= 0) {
						setRatePlan(defaultValue[0]);
					}
				}
			} else {
				setRatePlans([]);
			}
		} catch (error) {
			handleError();
		} finally {
			setLoadingRatePlans(false);
		}
	};

	const handleCreateReservation = async () => {
		try {
			if (!guestName || guestName === "" || !guestName) {
				setErrorMessage("Please insert a guest name.");
				setShowErrorMessage(true);
			} else if (!guestName || guestName.trim().split(" ").length === 1) {
				setErrorMessage("Please use a full name.");
				setShowErrorMessage(true);
			} else if (!guestEmail || !validateEmail(guestEmail)) {
				setErrorMessage("Please use a valid e-mail.");
				setShowErrorMessage(true);
			} else if (!guestPhoneNumber || !isValidPhoneNumber(guestPhoneNumber)) {
				setErrorMessage("Please use a valid phone number.");
				setShowErrorMessage(true);
			} else if (!roomType) {
				setErrorMessage("Please select a room type.");
				setShowErrorMessage(true);
			} else if (!ratePlan) {
				setErrorMessage("Please select a rate plan.");
				setShowErrorMessage(true);
			} else if (!room) {
				setErrorMessage("Please select a room.");
				setShowErrorMessage(true);
			} else {
				setErrorMessage("");
				setShowErrorMessage(false);

				const response = await createReservation();
				if (response) {
					dispatch(setWsMessageReceivedStatus(true));
					getReservationData(response.data.id);

					handlePaymentScreen("Reservation Payment", "Charge");
				} else {
					setErrorMessage("Reservation not created, please try again.");
					setShowErrorMessage(true);
				}
			}
		} catch (error) {
			setErrorMessage("Reservation not created, please try again.");
			setShowErrorMessage(true);
		}
	};
	const createReservation = async () => {
		const { apiEndpoint } = SERVICES.createReservation;
		setLoading(true);
		try {
			const response = await createReservationService(apiEndpoint, {
				propertyId: selectedProperty.value,
				guestName: guestName.trim(),
				guestEmail: guestEmail.trim(),
				guestPhoneNumber: guestPhoneNumber.trim(),
				roomTypeId: room.id,
				roomNumber: room.value,
				roomTypeName: room.roomTypeName,
				roomRateId: ratePlan.roomRateID,
				roomRatePlan: ratePlan.ratePlanNamePublic,
				bookingDate: moment(new Date()).format("YYYY-MM-DD").toString(),
				checkInDate: moment(checkInDate).format("YYYY-MM-DD").toString(),
				checkOutDate: moment(checkOutDate).format("YYYY-MM-DD").toString(),
				adminCheckinStatus: "",
				status: "not_confirmed",
				subTotal: subTotal,
				cityTax: cityTaxAmount,
				occupancyTax: occupancyTaxAmount,
				amenityFee: amenityFee,
				otherFees: otherFeesAmount,
				grandTotal: grandTotal
			});
			return response;
		} catch (error) {
			handleError();
		} finally {
			setLoading(false);
		}
	};
	const handleUpdateReservation = async () => {
		try {
			if (!guestName || guestName === "" || !guestName) {
				setErrorMessage("Please insert a guest name.");
				setShowErrorMessage(true);
			} else if (!guestName || guestName.trim().split(" ").length === 1) {
				setErrorMessage("Please use a full name.");
				setShowErrorMessage(true);
			} else if (!guestEmail || !validateEmail(guestEmail)) {
				setErrorMessage("Please use a valid e-mail.");
				setShowErrorMessage(true);
			} else if (!guestPhoneNumber || !isValidPhoneNumber(guestPhoneNumber)) {
				setErrorMessage("Please use a valid phone number.");
				setShowErrorMessage(true);
			} else if (!ratePlan) {
				setErrorMessage("Please select a rate plan.");
				setShowErrorMessage(true);
			} else if (!roomType) {
				setErrorMessage("Please select a room type.");
				setShowErrorMessage(true);
			} else if (!room) {
				setErrorMessage("Please select a room.");
				setShowErrorMessage(true);
			} else if (!roomChangeReason) {
				setErrorMessage("Please select a reservation change reason.");
				setShowErrorMessage(true);
			} else {
				setErrorMessage("");
				setShowErrorMessage(false);

				let response = await updateReservation();

				if (!response.success) {
					for (let index = 0; index < 5; index++) {
						response = await updateReservation();

						if (response.success) {
							index = 5;
						}
					}
				}

				if (response.success) {
					if (requireCharge) {
						await createNote();
					}

					if (Number(grandTotal) > Number(reservationData.grandtotal_amount)) {
						setPaymentDescription(`Room Upgrade - ${room.roomTypeName}`);
					} else {
						const description = `Reservation Change - New Room: ${room.value}, Room Type: ${room.roomTypeName}`;
						setPaymentDescription(description);
					}

					//refresh reservations
					dispatch(setWsMessageReceivedStatus(true));
					await getReservationData();

					if (requireCharge && status.value !== "canceled" && !disableCharge) {
						if (reservationData && reservationData.reservation_payment_method_id) {
							showChargeModal();
						} else {
							// payment
							handlePaymentScreen("Reservation Payment", "Charge");
						}
					} else {
						closeModal();
					}
				} else {
					setErrorMessage("Reservation not updated, please try again.");
					setShowErrorMessage(true);
				}
			}
		} catch (error) {
			setErrorMessage("Reservation not updated, please try again.");
			setShowErrorMessage(true);
		}
	};

	const updateReservation = async () => {
		const { apiEndpoint } = SERVICES.updateReservation;
		setLoading(true);

		try {
			const response = await updateReservationService(apiEndpoint, {
				reservationId: reservationId,
				propertyId: selectedProperty.value,
				guestName: guestName.trim(),
				guestEmail: guestEmail.trim(),
				guestPhoneNumber: guestPhoneNumber.trim(),
				roomTypeId: room.hasOwnProperty("roomTypeID") ? room.roomTypeID : null,
				roomId: room.hasOwnProperty("roomId") ? room.roomId : null,
				roomNumber: room.hasOwnProperty("value") ? room.value : null,
				roomTypeName: room.hasOwnProperty("roomTypeName") ? room.roomTypeName : null,
				roomRateId: ratePlan ? ratePlan.rateID : null,
				roomRatePlan: ratePlan ? ratePlan.ratePlanNamePublic : null,
				checkInDate: moment(checkInDate).format("YYYY-MM-DD").toString(),
				checkOutDate: moment(checkOutDate).format("YYYY-MM-DD").toString(),
				status: status.value,
				subTotal: subTotal,
				cityTax: cityTaxAmount,
				occupancyTax: occupancyTaxAmount,
				amenityFee: amenityFee,
				otherFees: otherFeesAmount,
				grandTotal: grandTotal,
				cardLastNumber: reservationData && reservationData.credit_card_no
			});
			return response;
		} catch (error) {
			handleError();
		} finally {
			setLoading(false);
		}
	};

	const createNote = async () => {
		const { apiEndpoint } = SERVICES.createNote;
		setLoading(true);

		const noteDescription = `Reservation Change - Reason: ${
			roomChangeReason.label
		}, Grand Total: ${grandTotal}, Old Room: ${
			reservationData ? reservationData.room_no : ""
		}, New Room: ${room.value}, Room Type: ${room.roomTypeName}`;

		try {
			const user = await Auth.currentUserInfo();

			const response = await createNoteService(apiEndpoint, {
				propertyId: selectedProperty.value,
				reservationId: reservationId,
				note: noteDescription,
				deleteAvailable: false,
				createdBy: user.attributes.name
			});
			return response;
		} catch (error) {
			handleError();
		} finally {
			setLoading(false);
		}
	};
	// eslint-disable-next-line
	const updatePaymentAmount = async (paymentAmount) => {
		const { apiEndpoint } = SERVICES.updatePaymentAmount;
		setLoading(true);

		try {
			const response = await updatePaymentAmountService(apiEndpoint, {
				reservationId: reservationId,
				propertyId: selectedProperty.value,
				amount: paymentAmount
			});
			return response;
		} catch (error) {
			handleError();
		} finally {
			setLoading(false);
		}
	};

	const handleChargeReservationAutomatically = async () => {
		try {
			const res = await chargeReservationAutomatically();
			if (res) {
				await updateReservationStatusToConfirmed();

				dispatch(setWsMessageReceivedStatus(true));
				await getReservationData();
				closeModal();
			}
		} catch (error) {
			handleError();
		}
	};

	const chargeReservationAutomatically = async () => {
		const { apiEndpoint } = SERVICES.chargeReservationAutomatically;
		setLoading(true);

		try {
			let description = "Reservation Payment";

			if (Number(grandTotal) > Number(reservationData.grandtotal_amount)) {
				description = `Room Upgrade - ${room.roomTypeName}`;
			} else {
				description = `Reservation Change - New Room: ${room.value}, Room Type: ${room.roomTypeName}`;
			}

			const response = await chargeReservationAutomaticallyService(apiEndpoint, {
				reservationId: reservationId,
				propertyId: selectedProperty.value,
				description: description,
				amount: grandTotal,
				cardLastNumber: reservationData && reservationData.credit_card_no
			});
			return response;
		} catch (error) {
			handleError();
		} finally {
			setLoading(false);
		}
	};

	const updateReservationStatusToConfirmed = async () => {
		const { apiEndpoint } = SERVICES.updateReservationStatusToConfirmed;
		setLoading(true);

		try {
			const response = await updateReservationStatusToConfirmedService(apiEndpoint, {
				propertyId: selectedProperty.value,
				reservationId: reservationId
			});
			return response;
		} catch (error) {
			handleError();
		} finally {
			setLoading(false);
		}
	};
	// eslint-disable-next-line
	const refundReservation = async () => {
		const { apiEndpoint } = SERVICES.refundReservation;
		setLoading(true);

		try {
			const response = await refundReservationService(apiEndpoint, {
				propertyId: selectedProperty.value,
				reservationId: reservationId
			});
			return response;
		} catch (error) {
			handleError();
		} finally {
			setLoading(false);
		}
	};

	const handleError = (error) => {
		setLoading(false);

		if (error) {
			console.log(error);
		}
	};

	const handleCheckInDateSelect = async (newDate) => {
		setCheckInDate(newDate);
		let checkOutDateModified = moment(checkOutDate).format("MM/DD/YYYY");

		if (moment(checkOutDateModified).isSameOrBefore(newDate)) {
			checkOutDateModified = moment(newDate).add(1, "days").toDate();
			setCheckOutDate(checkOutDateModified);
		}
		setCheckInDateVisible(false);

		setSubtotal(0);
		calculateReservationTotal();

		await getRoomTypes(newDate, checkOutDateModified);
	};

	const toggleCheckInDatePicker = () => {
		setCheckInDateVisible(!checkInDateVisible);
	};

	const toggleCheckOutDatePicker = () => {
		setCheckOutDateVisible(!checkOutDateVisible);
	};

	const handleCheckOutDateSelect = async (newDate) => {
		setCheckOutDate(newDate);
		setCheckOutDateVisible(false);

		setSubtotal(0);
		calculateReservationTotal();

		await getRoomTypes(checkInDate, newDate);
	};

	const handleChangeRoom = (selectedRoom) => {
		setRoom(selectedRoom);
	};

	const handleChangeRoomType = async (selectedRoomType) => {
		try {
			setRoomType(selectedRoomType);

			await getRatePlans(selectedRoomType.roomTypeID, checkInDate, checkOutDate);
			await getCloudBedsRooms(selectedRoomType.roomTypeID, checkInDate, checkOutDate);
		} catch (error) {
			handleError();
		}
	};

	const handleChangeRatePlan = async (selectedRatePlan) => {
		setRatePlan(selectedRatePlan);
		setSubtotal(selectedRatePlan.totalRate.toFixed(2));
	};

	const handleChangeStatus = (statusOption) => {
		setStatus(statusOption);
	};

	const handleRoomChangeReason = (roomChangeReasonOption) => {
		setRoomChangeReason(roomChangeReasonOption);
	};

	const getReservationData = async (id = null) => {
		const { apiEndpoint } = SERVICES.getReservationData;
		setLoading(true);
		setProcessing(true);
		try {
			const response = await getReservationDataService(apiEndpoint, {
				reservationId: id ? id : reservationId,
				propertyId: selectedProperty.value
			});

			if (response && edit) {
				setCheckInDate(moment().toDate());
				setCheckOutDate(moment(response.checkout_date).add(1, "days").toDate());
				setGuestName(response.guest_name);
				setGuestEmail(response.guest_email);
				setGuestPhoneNumber(response.guest_phone_no);
				setSubtotal(response.subtotal_amount);
				setCityTaxAmount(response.city_tax_amount);
				setOccupancyTaxAmount(response.occupancy_tax_amount);
				setGrandTotal(response.grandtotal_amount);
				setOtherFeesAmount(response.other_fees_amount);

				if (response.status === "Confirmed") {
					setStatus({ label: "Confirmed", value: "confirmed" });
				} else if (response.status === "Not-confirmed") {
					setStatus({ label: "Not-confirmed", value: "not_confirmed" });
				} else if (response.status === "Canceled") {
					setStatus({ label: "Canceled", value: "canceled" });
				} else if (response.status === "Checked-in") {
					setStatus({ label: "Checked-in", value: "checked_in" });
				} else if (response.status === "Checked-out") {
					setStatus({ label: "Checked-out", value: "checked_out" });
				} else if (response.status === "No-show") {
					setStatus({ label: "No-show", value: "no_show" });
				}
			}

			setReservationData(response);
		} catch (error) {
			handleError();
		} finally {
			setLoading(false);
			setProcessing(false);
		}
	};

	const showChargeModal = () => {
		confirmAlert({
			title: "Charge Reservation",
			message: `Do you want to charge the reservation automatically?`,

			buttons: [
				{
					label: "Manual Charge",
					onClick: () => {
						handlePaymentScreen("Reservation Payment", "Charge");
					}
				},
				{
					label: "Yes",
					onClick: () => {
						handleChargeReservationAutomatically();
					}
				}
			]
		});
	};

	return (
		<>
			{open && (
				<div className="reservation-management-overlay-wrapper">
					{loading ||
					processing ||
					loadingRooms ||
					loadingAvailableRoomTypes ||
					loadingRatePlans ? (
						<div className="reservation-management-loading-overlay">
							<div className="loader">
								<Loader type="Oval" color="#030303" height={50} width={50} />
							</div>
						</div>
					) : null}

					<div className={`reservation-management-wrap ${open && "active"}`}>
						<div className="close-button" onClick={(e) => !loading && closeModal()}>
							<img alt="" src={CloseIcon} width="24px" height="24px" />
						</div>
						{/* Reservation Screen*/}
						{screen === "RESERVATION" && (
							<>
								<div
									style={{
										display: "flex",
										flexDirection: "row",
										alignItems: "center",
										justifyContent: "flex-start",
										gap: "10px"
									}}
								>
									<h2> {edit ? "Edit Reservation" : "Create New Reservation"}</h2>
								</div>
								<div
									style={{
										display: "flex",
										flexDirection: "column",
										alignItems: "center",
										justifyContent: "space-between",
										gap: "20px"
									}}
								>
									<div className="date-picker-container">
										<div
											className="date-picker-item"
											onMouseEnter={toggleCheckInDatePicker}
											onMouseLeave={toggleCheckInDatePicker}
										>
											<div className="date-picker">
												<div className="date-picker-title">
													<div className="date-picker-icon">
														<CalendarIcon />
													</div>
													<label className="date-picker-label">
														Check-in
													</label>
												</div>

												<span className="date-picker-date">
													{checkInDate
														? moment(checkInDate).format("MM/DD/YYYY")
														: null}
												</span>
											</div>
											{checkInDateVisible ? (
												<DatePicker
													onChange={handleCheckInDateSelect}
													selected={checkInDate}
													minDate={moment(new Date())
														.subtract(1, "days")
														.toDate()}
													className="date-picker-calendar"
												/>
											) : null}
										</div>

										<div
											className="date-picker-item"
											onMouseEnter={toggleCheckOutDatePicker}
											onMouseLeave={toggleCheckOutDatePicker}
										>
											<div className="date-picker">
												<div className="date-picker-title">
													<div className="date-picker-icon">
														<CalendarIcon />
													</div>
													<label className="date-picker-label">
														Check-out
													</label>
												</div>
												<span className="date-picker-date">
													{checkOutDate
														? moment(checkOutDate).format("MM/DD/YYYY")
														: null}
												</span>
											</div>
											{checkOutDateVisible ? (
												<DatePicker
													onChange={handleCheckOutDateSelect}
													selected={checkOutDate}
													minDate={moment(checkInDate)
														.add(1, "days")
														.toDate()}
													className="date-picker-calendar"
												/>
											) : null}
										</div>
									</div>

									<TextField
										extraStyles="text-field"
										label="Guest Name"
										placeHolder="Name"
										value={guestName}
										setValue={setGuestName}
										disabled={loading}
									/>
									<TextField
										extraStyles="text-field"
										label="Guest E-mail"
										placeHolder="E-mail"
										value={guestEmail}
										setValue={setGuestEmail}
										disabled={loading}
									/>

									<PhoneField
										extraStyles="text-field"
										label="Guest Phone Number"
										placeHolder="Phone Number"
										defaultCountry="US"
										value={guestPhoneNumber}
										setValue={setGuestPhoneNumber}
										disabled={loading}
									/>
								</div>

								<div
									style={{
										paddingTop: "15px"
									}}
								>
									<div className={"form-field text-field"}>
										<label className="field-title">ROOM TYPE</label>
									</div>

									<Select
										isClearable={false}
										isSearchable={false}
										isLoading={loading || loadingAvailableRoomTypes}
										isDisabled={loading || loadingAvailableRoomTypes}
										placeholder={
											edit && roomType
												? `Change a Room Type (current: ${roomType})`
												: "Select a Room Type"
										}
										theme={(theme) => ({
											...theme,
											borderRadius: 4,
											height: "100px",
											colors: {
												...theme.colors,
												primary25: "#FFD865",
												primary50: "#FFD865",
												primary: "black"
											}
										})}
										style={{
											width: `100px`
										}}
										options={availableRoomTypes}
										value={roomType}
										onChange={(event) => {
											handleChangeRoomType(event);
										}}
									/>
								</div>

								<div
									style={{
										paddingTop: "15px"
									}}
								>
									<div className={"form-field text-field"}>
										<label className="field-title">RATE PLAN</label>
									</div>

									<Select
										isClearable={false}
										isSearchable={false}
										isLoading={loading || loadingRatePlans}
										isDisabled={
											loading ||
											loadingAvailableRoomTypes ||
											loadingRatePlans ||
											!roomType
										}
										placeholder={
											edit && ratePlan
												? `Change a Rate Plan (current: ${subTotal})`
												: "Select a Rate Plan"
										}
										theme={(theme) => ({
											...theme,
											borderRadius: 4,
											height: "100px",
											colors: {
												...theme.colors,
												primary25: "#FFD865",
												primary50: "#FFD865",
												primary: "black"
											}
										})}
										style={{
											width: `100px`
										}}
										options={ratePlans}
										value={ratePlan}
										onChange={(event) => {
											handleChangeRatePlan(event);
										}}
									/>
								</div>

								<div
									style={{
										paddingTop: "15px"
									}}
								>
									<div className={"form-field text-field"}>
										<label className="field-title">ROOM</label>
									</div>

									<Select
										isClearable={false}
										isSearchable={false}
										isLoading={loading || loadingRooms}
										isDisabled={
											loading ||
											loadingRooms ||
											loadingAvailableRoomTypes ||
											loadingRatePlans ||
											!roomType ||
											!ratePlan
										}
										placeholder={
											edit && room
												? `Change a Room (current: ${room})`
												: "Select a Room"
										}
										theme={(theme) => ({
											...theme,
											borderRadius: 4,
											height: "100px",
											colors: {
												...theme.colors,
												primary25: "#FFD865",
												primary50: "#FFD865",
												primary: "black"
											}
										})}
										style={{
											width: `100px`
										}}
										options={rooms}
										value={room}
										onChange={(event) => {
											handleChangeRoom(event);
										}}
									/>
								</div>

								{edit && (
									<div
										style={{
											paddingTop: "15px"
										}}
									>
										<div className={"form-field text-field"}>
											<label className="field-title">STATUS</label>
										</div>

										<Select
											isClearable={false}
											isSearchable={false}
											isLoading={loading}
											isDisabled={loading}
											placeholder={"Select a status"}
											theme={(theme) => ({
												...theme,
												borderRadius: 4,
												height: "100px",
												colors: {
													...theme.colors,
													primary25: "#FFD865",
													primary50: "#FFD865",
													primary: "black"
												}
											})}
											style={{
												width: `100px`
											}}
											options={statusOptions}
											value={status}
											onChange={(event) => {
												handleChangeStatus(event);
											}}
											defaultValue={statusOptions.filter(
												(item) => item.value === "Checked-in"
											)}
										/>
									</div>
								)}

								{edit && (
									<div
										style={{
											paddingTop: "15px"
										}}
									>
										<div className={"form-field text-field"}>
											<label className="field-title">CHANGE REASON</label>
										</div>

										<Select
											isClearable={false}
											isSearchable={false}
											isLoading={loading}
											isDisabled={loading}
											placeholder={"Select a reservation change reason"}
											theme={(theme) => ({
												...theme,
												borderRadius: 4,
												height: "100px",
												colors: {
													...theme.colors,
													primary25: "#FFD865",
													primary50: "#FFD865",
													primary: "black"
												}
											})}
											style={{
												width: `100px`
											}}
											options={roomChangeReasons}
											value={roomChangeReason}
											onChange={(event) => {
												handleRoomChangeReason(event);
											}}
										/>
									</div>
								)}

								{!edit ? (
									<div
										style={{
											display: "flex",
											flexDirection: "column",
											alignItems: "flex-end",
											justifyContent: "flex-end",
											color: "#4C4C4C",
											paddingTop: "15px"
										}}
									>
										<span>{`Sub total: $${subTotal}`} </span>
										<span style={{ paddingTop: "5px" }}>
											{`City Tax: $${cityTaxAmount}`}{" "}
										</span>
										<span style={{ paddingTop: "5px" }}>
											{`Occupancy Tax: $${occupancyTaxAmount}`}{" "}
										</span>
										<span style={{ paddingTop: "5px" }}>
											{`Amenity Fee: $${amenityFee}`}{" "}
										</span>
										<span style={{ paddingTop: "5px" }}>
											{`Other Fees: $${
												otherFeesAmount ? otherFeesAmount : 0.0
											}`}{" "}
										</span>
										<span
											style={{
												paddingTop: "10px",
												fontWeight: "600",
												paddingBottom: "5px"
											}}
										>
											{`Grand Total: $${grandTotal}`}{" "}
										</span>
									</div>
								) : (
									<div
										style={{
											display: "flex",
											flexDirection: "column",
											alignItems: "flex-end",
											justifyContent: "flex-end",
											color: "#4C4C4C",
											paddingTop: "15px"
										}}
									>
										<span>{`Sub total: $${subTotal}`} </span>
										<span style={{ paddingTop: "5px" }}>
											{`City Tax: $${cityTaxAmount}`}{" "}
										</span>
										<span style={{ paddingTop: "5px" }}>
											{`Occupancy Tax: $${occupancyTaxAmount}`}{" "}
										</span>
										<span style={{ paddingTop: "5px" }}>
											{`Amenity Fee: $${amenityFee}`}{" "}
										</span>
										<span style={{ paddingTop: "5px" }}>
											{`Other Fees: $${
												otherFeesAmount ? otherFeesAmount : 0.0
											}`}{" "}
										</span>
										<span
											style={{
												paddingTop: "10px",
												fontWeight: "600",
												paddingBottom: "5px"
											}}
										>
											{`Grand Total: $${grandTotal}`}{" "}
										</span>

										{reservationData &&
										reservationData.reservation_payment_amount > 0 ? (
											<span
												style={{
													display: "flex",
													alignItems: "center",
													paddingTop: "10px",
													fontWeight: "500"
												}}
											>
												{reservationData &&
													`Amount Paid: $${reservationData.reservation_payment_amount}`}

												<SuccessIcon />
											</span>
										) : null}

										{reservationData &&
										reservationData.reservation_payment_method_id &&
										Number(reservationData.grandtotal_amount) ===
											Number(grandTotal) &&
										Number(reservationData.grandtotal_amount) > 0 ? (
											<span
												onClick={(e) => {
													handlePaymentScreen(
														"Change Credit Card",
														"Change"
													);
												}}
												style={{
													paddingTop: "10px",
													fontWeight: "500",
													paddingBottom: "5px",
													textDecoration: "underline",
													cursor: "pointer"
												}}
											>
												Change Credit Card
											</span>
										) : null}

										{reservationData &&
										Number(reservationData.grandtotal_amount) ===
											Number(grandTotal) &&
										Number(reservationData.grandtotal_amount) > 0 &&
										Number(reservationData.reservation_payment_amount) === 0 ? (
											<span
												onClick={(e) => {
													handlePaymentScreen(
														"Reservation Payment",
														"Charge"
													);
												}}
												style={{
													paddingTop: "10px",
													fontWeight: "500",
													paddingBottom: "5px",
													textDecoration: "underline",
													cursor: "pointer"
												}}
											>
												Charge Reservation
											</span>
										) : null}

										{requireCharge && (
											<div style={{ minHeight: "30px", paddingTop: "10px" }}>
												<label className="checkbox-container">
													<input
														disabled={loading}
														type="checkbox"
														onChange={(event) => {
															setDisableCharge(event.target.checked);
														}}
														checked={disableCharge}
													/>
													<span className="checkmark"></span>
													Do not charge
												</label>
											</div>
										)}
									</div>
								)}

								<div
									style={{
										display: "flex",
										flexDirection: "row",
										alignItems: "center",
										justifyContent: "flex-start",
										color: "red",
										paddingTop: "15px"
									}}
								>
									{showErrorMessage ? errorMessage : ""}
								</div>

								<div className="btn-container-row">
									<button
										className="cancel-item-btn"
										onClick={handleCancel}
										disabled={loading}
									>
										<span>Cancel</span>
									</button>

									<AppButton
										isLoading={loading}
										label="Save"
										onClick={(e) => {
											edit
												? handleUpdateReservation()
												: handleCreateReservation();
										}}
										isDisabled={loading || subTotal <= 0 || !subTotal}
									/>
								</div>
							</>
						)}

						{screen === "PAYMENT" && (
							<>
								<div
									style={{
										display: "flex",
										flexDirection: "row",
										alignItems: "center",
										justifyContent: "flex-start",
										gap: "10px"
									}}
								>
									<h2>{paymentTitle}</h2>
								</div>

								<div
									style={{
										display: "flex",
										flexDirection: "column",
										alignItems: "flex-start",
										justifyContent: "flex-end",
										color: "#4C4C4C",
										paddingBottom: "15px"
									}}
								>
									<span
										style={{
											display: "flex",
											flexDirection: "row",
											paddingBottom: "10px"
										}}
									>
										<span style={{ width: "100px", fontWeight: "600" }}>
											Guest Name:
										</span>

										{loading ? (
											<span>
												<SkeletonLoader width={100} height={10} />
											</span>
										) : (
											<span>
												{`${reservationData && reservationData.guest_name}`}
											</span>
										)}
									</span>

									<span
										style={{
											display: "flex",
											flexDirection: "row",
											paddingBottom: "15px"
										}}
									>
										<span style={{ width: "100px", fontWeight: "600" }}>
											Reservation:
										</span>

										{loading ? (
											<span>
												<SkeletonLoader width={100} height={10} />
											</span>
										) : (
											<span>
												{reservationData && reservationData.reservation_id}
											</span>
										)}
									</span>
								</div>

								<div style={{ paddingTop: "15px" }}>
									<Elements stripe={stripeToken} options={ELEMENTS_OPTIONS}>
										<CheckoutForm
											propertyId={selectedProperty && selectedProperty.value}
											reservationId={reservationData && reservationData.id}
											description={
												edit ? paymentDescription : "Reservation Payment"
											}
											amount={grandTotal && grandTotal}
											handleCancel={handleCancel}
											processing={processing}
											setProcessing={setProcessing}
											params={{
												subTotal: subTotal,
												cityTaxAmount: cityTaxAmount,
												occupancyTaxAmount: occupancyTaxAmount,
												amenityFee: amenityFee,
												otherFeesAmount: otherFeesAmount,
												grandTotal: grandTotal
											}}
											button={paymentButton}
										/>
									</Elements>
								</div>
							</>
						)}
					</div>
				</div>
			)}
		</>
	);
};

const mapStateToProps = (state) => {
	const { isWsMessageReceived } = state.reservations;
	return {
		isWsMessageReceived
	};
};

const mapDispatchToProps = {
	setWsMessageReceivedStatus
};

export default connect(mapStateToProps, mapDispatchToProps)(ReservationManagement);
