import React, { FC, useReducer } from "react";
import { PropertiesContext, propertiesReducer } from ".";
import { PropertiesRepositoryImpl } from "../data/repositories/properties-repository-impl";
import { Property } from "../domain/entities/property";
import { PropertiesRepository } from "../domain/repositories/properties-repository";
import { CreateProperty } from "../domain/useCases/create-property";
import { DeleteProperty } from "../domain/useCases/delete-property";
import { GetProperties } from "../domain/useCases/get-properties";
import { UpdateProperty } from "../domain/useCases/update-property";
import { AssignAdminsToProperty } from "../domain/useCases/assign-admins-to-property";
import { SyncReservationsFromCloudBeds } from "../domain/useCases/sync-reservations-from-cloudbeds";

export interface PropertiesState {
	properties: Property[];
}

const PROPERTIES_INITIAL_STATE: PropertiesState = {
	properties: []
};

type Props = {
	children: React.ReactNode;
};

export const PropertiesProvider: FC<Props> = ({ children }) => {
	const [state, dispatch] = useReducer(propertiesReducer, PROPERTIES_INITIAL_STATE);

	const initRepository = (): PropertiesRepository => {
		const propertiesRepository = new PropertiesRepositoryImpl();
		return propertiesRepository;
	};

	const getPropertiesByUser = async (): Promise<void> => {
		const propertiesRepository = initRepository();
		const getPropertiesByUser = new GetProperties(propertiesRepository);
		const properties = await getPropertiesByUser.execute();
		dispatch({ type: "GET_PROPERTIES_BY_USER", payload: { properties } });
	};

	const deleteProperty = async (propertyId: number): Promise<void> => {
		const propertiesRepository = initRepository();
		const deleteProperty = new DeleteProperty(propertiesRepository);
		await deleteProperty.execute(propertyId);
		dispatch({ type: "DELETE_PROPERTY", payload: { propertyId } });
	};

	const updateProperty = async (updatedProperty: Property): Promise<void> => {
		const propertiesRepository = initRepository();
		const updateProperty = new UpdateProperty(propertiesRepository);
		await updateProperty.execute(updatedProperty);
		dispatch({ type: "UPDATE_PROPERTY", payload: { updatedProperty } });
	};

	const createProperty = async (property: Property): Promise<void> => {
		const propertiesRepository = initRepository();
		const createProperty = new CreateProperty(propertiesRepository);
		await createProperty.execute(property);
		dispatch({ type: "CREATE_PROPERTY", payload: { property } });
	};

	const assignAdminsToProperty = async (propertyId: number, adminIds: number[]): Promise<void> => {
		const propertiesRepository = initRepository();
		const assignAdminsToProperty = new AssignAdminsToProperty(propertiesRepository);
		await assignAdminsToProperty.execute(propertyId, adminIds);
		dispatch({ type: "ASSIGN_ADMINS_TO_PROPERTY", payload: { propertyId, adminIds } });
	};

	const syncReservationsFromCloudBeds = async (propertyId: number, fromDate: string): Promise<void> => {
		const propertiesRepository = initRepository();
		const syncReservations = new SyncReservationsFromCloudBeds(propertiesRepository);
		await syncReservations.execute(propertyId, fromDate);
	};

	return (
		<PropertiesContext.Provider
			value={{
				...state,

				// Methods
				getPropertiesByUser,
				deleteProperty,
				updateProperty,
				createProperty,
				assignAdminsToProperty,
				syncReservationsFromCloudBeds,
			}}
		>
			{children}
		</PropertiesContext.Provider>
	);
};
