import { Auth } from "aws-amplify";
import { getItemFromLocalStorage } from "./localStorageUtils";

export const getJwtToken = async () => {
	try {
		return (await Auth.currentSession()).getIdToken().getJwtToken();
	} catch (error) {}
};

export const getCognitoUserPoolId = async () => {
	try {
		return (await Auth.currentAuthenticatedUser()).username;
	} catch (error) {}
};

export const getCognitoUser = async () => {
	try {
		return await Auth.currentUserInfo();
	} catch (error) {}
};

export const isValidSession = (rootRequired) => {
	try {
		const cache = getItemFromLocalStorage("amplify-signin-with-hostedUI");
		const user = getItemFromLocalStorage(
			`CognitoIdentityServiceProvider.${process.env.REACT_APP_USER_POOL_WEBCLIENT_ID}.LastAuthUser`
		);

		if (rootRequired) {
			const userData = getItemFromLocalStorage(
				`CognitoIdentityServiceProvider.${process.env.REACT_APP_USER_POOL_WEBCLIENT_ID}.${user}.userData`
			);
			const userAttributes = JSON.parse(userData).UserAttributes;

			const role = userAttributes.find((attr) => attr.Name === "custom:role").Value;
			const isRoot = role === "root";

			return cache && user && isRoot;
		}

		return cache && user;
	} catch (error) {
		console.error(error);
	}
};

export const hasValidRole = (roles) => {
	try {
		const cache = getItemFromLocalStorage("amplify-signin-with-hostedUI");
		const user = getItemFromLocalStorage(
			`CognitoIdentityServiceProvider.${process.env.REACT_APP_USER_POOL_WEBCLIENT_ID}.LastAuthUser`
		);

		if (!roles || roles.length === 0) {
			return cache && user;
		}

		const userData = getItemFromLocalStorage(
			`CognitoIdentityServiceProvider.${process.env.REACT_APP_USER_POOL_WEBCLIENT_ID}.${user}.userData`
		);
		const userAttributes = JSON.parse(userData).UserAttributes;

		const role = userAttributes.find((attr) => attr.Name === "custom:role").Value;
		const hasValidRole = roles.includes(role);

		return cache && user && hasValidRole;
	} catch (error) {
		console.error(error);
	}
};
