import { useEffect, useState } from "react";
import { GenericError } from "src/app/components/GenericError";
import { useSelector } from "react-redux";
import { useRoomsLocks } from "src/app/rooms-locks/hooks/useRoomsLocks";
import { RoomsLocksTable } from "./components/LocksTable";
import { QrCodePopup } from "./components/QrCodePopup";
import { TableHeader } from "./components/TableHeader";

enum ViewStatus {
	INITIAL = "INITIAL",
	LOADING = "LOADING",
	ERROR = "ERROR",
	SUCCESS = "SUCCESS"
}

interface QrCodePopupProps {
	isOpened: boolean;
	isLoading: boolean;
	encodedQr: string;
}

const RoomsLocks = () => {
	const [status, setStatus] = useState<ViewStatus>(ViewStatus.INITIAL);
	const [qrCodePopupData, setQrCodePopupData] = useState<QrCodePopupProps>({
		isOpened: false,
		isLoading: false,
		encodedQr: ""
	});
	const { roomsLocks, fetchRoomsLocks, getConfigQr } = useRoomsLocks();
	const { selectedProperty } = useSelector((state: any) => state.reservations);

	useEffect(() => {
		fetchRoomsLocksByProperty();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedProperty]);

	async function fetchRoomsLocksByProperty() {
		try {
			setStatus(ViewStatus.LOADING);
			if (selectedProperty != null) {
				const propertyId = selectedProperty.value;
				await fetchRoomsLocks(propertyId);
				setStatus(ViewStatus.SUCCESS);
			}
		} catch (error) {
			console.error(error);
			setStatus(ViewStatus.ERROR);
		}
	}

	function refreshRoomsLocks() {
		fetchRoomsLocksByProperty();
	}

	async function onGetQrPressed(lockId: string) {
		try {
			setQrCodePopupData({ isOpened: true, isLoading: true, encodedQr: "" });
			const encodedQrCode = await getConfigQr(lockId);
			setQrCodePopupData({ isOpened: true, isLoading: false, encodedQr: encodedQrCode });
		} catch (error) {
			console.error(error);
		}
	}

	function closeQrPopup() {
		setQrCodePopupData({ isOpened: false, isLoading: false, encodedQr: "" });
	}

	function getRoomsLocksForSelectedProperty() {
		if (!selectedProperty) return [];
		if (!roomsLocks) return [];
		if (!roomsLocks.has(selectedProperty.value)) return [];
		return roomsLocks.get(selectedProperty.value) || [];
	}

	if (status === ViewStatus.ERROR) {
		return (
			<div className="right-wrapper">
				{" "}
				<GenericError />
			</div>
		);
	}

	return (
		<div className="right-wrapper">
			<TableHeader onRefreshClicked={refreshRoomsLocks} />
			<RoomsLocksTable
				isLoading={status === ViewStatus.LOADING}
				onGetQrPressed={onGetQrPressed}
				roomsLocks={getRoomsLocksForSelectedProperty()}
			/>
			<QrCodePopup
				isOpened={qrCodePopupData.isOpened}
				isLoading={qrCodePopupData.isLoading}
				encodedQr={qrCodePopupData.encodedQr}
				onClose={closeQrPopup}
			/>
		</div>
	);
};

export default RoomsLocks;
