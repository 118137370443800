import { FC } from "react";
import { AppButton } from "src/app/components/AppButton";

interface Props {
	onRefreshClicked: () => void;
}

export const TableHeader: FC<Props> = ({ onRefreshClicked }) => {
	return (
		<div className="tabs-wrapper">
			<h1>Locks</h1>
			<div
				style={{
					display: "flex",
					paddingLeft: "40px",
					paddingRight: "40px",
					alignItems: "center",
					justifyContent: "flex-start"
				}}
			>
				<span>
					<AppButton label="Refresh" onClick={onRefreshClicked} />
				</span>
			</div>
		</div>
	);
};
